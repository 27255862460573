// @ts-nocheck
export const config = {
  baseUrl: window['env']['API_URL'] || process.env.REACT_APP_API_URL,
  suffixApi: '/api',
  versionApi: '/v1',
  paymentTransactionsPageSize: 10,
  homeownersPageSize: 10,
  lockboxFilesPageSize: 10,
  onboardingPageSize: 10,
  taxFormsPageSize: 50,
  welcomeRoute: '/Home',
  domain: window['env']['AUTH_DOMAIN'] || process.env.REACT_APP_AUTH_DOMAIN || '',
  clientId: window['env']['CLIENT_ID'] || process.env.REACT_APP_AUTH_CLIENT_ID || '',
  audience: window['env']['AUTH_AUDIENCE'] || process.env.REACT_APP_AUTH_AUDIENCE || '',
  redirectUri: window.location.origin + process.env.PUBLIC_URL,
  instrumentationKey: window['env']['INSTRUMENTATION_KEY'] || process.env.REACT_APP_INSTRUMENTATION_KEY || '',
  stripeKey: window['env']['STRIPE_KEY'] || process.env.STRIPE_KEY || ''
};
