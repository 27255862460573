import {RepresentativeUsersInterface} from "../store";
import {RepresentativeUsersTypes} from "../actions/representative.user.action";
import {
  CLOSE_REPRESENTATIVE_USER_MODAL,
  REPRESENTATIVE_USER_INITIALIZED,
  HIDE_REPRESENTATIVE_USER_LOADING, OPEN_REPRESENTATIVE_USER_MODAL,
  RECEIVE_REPRESENTATIVE_USER_INITIAL_DATA,
  RECEIVE_REPRESENTATIVE_USERS, SHOW_REPRESENTATIVE_USER_LOADING
} from "../constants/representativeUserConstants";

export const representativeUsersReducer = (state : RepresentativeUsersInterface = getRepresentativeUsersInitialState(), action : RepresentativeUsersTypes): RepresentativeUsersInterface => {
  switch (action.type) {
    case RECEIVE_REPRESENTATIVE_USERS:
      return {
        ...state,
        catalog: {
          ...state.catalog,
          users: [...action.users]
        }
      }
    case RECEIVE_REPRESENTATIVE_USER_INITIAL_DATA:
      return {
        ...state,
        initialized: true,
        catalog: {
          managementCompanies: action.managementCompanies
        }
      }
    case SHOW_REPRESENTATIVE_USER_LOADING:
      return {
        ...state,
        loading: true
      }
    case HIDE_REPRESENTATIVE_USER_LOADING:
      return {
        ...state,
        loading: false
      }
    case REPRESENTATIVE_USER_INITIALIZED:
      return {
        ...state,
        initialized: true
      }
    case OPEN_REPRESENTATIVE_USER_MODAL:
      return {
        ...state,
        openRepresentativeUserModal: true,
        modalType: action.modalType,
      }
    case CLOSE_REPRESENTATIVE_USER_MODAL:
      return {
        ...state,
        openRepresentativeUserModal: false,
      }
    default:
      return state
  }
}

const getRepresentativeUsersInitialState = (): RepresentativeUsersInterface => {
  return {
    initialized: false,
    loading: false,
    catalog: {},
    openRepresentativeUserModal: false
  }
}
