export default interface PaymentScheduledInterface {
  id: number
  nextPaymentDate: Date
  payerId: number
  paymentMethodId: number
  amountType: ScheduleAmountType
  amount: number
  firstPaymentDate: Date
  expirationDate: Date
  numberOfPayments: number
  expired: boolean
  frequency: ScheduleFrequency
  dayOfMonth: number
  monthInPeriod: number
  currencyCode: CurrencyCode
  memo: string
  fee: number
}

export enum ScheduleAmountType {
  Fixed = 0,
  BalanceDue = 1
}

export enum ScheduleFrequency {
  Monthly = 0,
  Quarterly = 1,
  SemiAnnually = 2,
  Annually = 3,
  OneTime = 4
}

export enum CurrencyCode {
  USD
}
