import {CLOSE_NOTIFICATION, HANDLE_ERROR, NOTIFY, SHOW_MODAL} from "../constants/notificationHandler";
import {NotificationHandlerInterface} from "../store";
import {CloseNotificationAction, HandleErrorAction, NotifyAction, ShowModalAction} from "./notification.handler.action";

export const sendNotification = (notification: NotificationHandlerInterface): NotifyAction => ({
  type: NOTIFY,
  notification
})

export const showModalDialog = (notification: NotificationHandlerInterface): ShowModalAction => ({
  type: SHOW_MODAL,
  notification
})

export const closeNotification =  (notificationID: string): CloseNotificationAction => ({
  type: CLOSE_NOTIFICATION,
  notificationID
})

export const handleErrorAction = (code: number, message: string, hintMessage: string): HandleErrorAction => ({
  type: HANDLE_ERROR,
  code,
  message,
  hintMessage
})
