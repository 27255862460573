import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        logo: {
            alignItems: "flex-end",
            height: "25px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            margin: "27px",
            width: "25px"
        },
        fill1: {
            height: "4.8px",
            width: "8.65px",
            backgroundColor: "#6DD26D"
        },
        fill2: {
            height: "4.8px",
            width: "16.35px",
            backgroundColor: "#6DD26D"
        },
        fill3: {
            height: "4.8px",
            width: "25px",
            backgroundColor: "#6DD26D"
        }    
    })
);

const Logo = () => {
    const classes = useStyles();
    return(
        <div className={classes.logo}>
            <div className={classes.fill1} />
            <div className={classes.fill2} />
            <div className={classes.fill3} />
        </div>
    )
}

export default Logo