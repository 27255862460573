export interface UserCompanyInterface {
  id: number
  name: string
  addressAddress1: string
  addressAddress2: number
  addressCity: string
  addressStateOrRegion: string
  addressCountry: string
  caliberManagementCompanyId: number
  sendPaymentsTo: SendPaymentsTo
  scheduleReportsDisabled?: boolean
  reportsEnabled: boolean
}

export enum SendPaymentsTo
{
  None = 1,
  CalPay = 2,
  LockBoxFile = 3
}
