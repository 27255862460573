/**
 * TaxForms constants
 */
export const UPDATE_TAX_FORMS_FILTER = 'UPDATE_TAX_FORMS_FILTER'
export const FETCH_TAX_FORMS = 'FETCH_TAX_FORMS'
export const RECEIVE_TAX_FORMS = 'RECEIVE_TAX_FORMS'
export const RECEIVE_TAX_FORMS_INITIAL_DATA = 'RECEIVE_TAX_FORMS_INITIAL_DATA'
export const FETCH_TAX_FORMS_INITIAL_DATA = 'FETCH_TAX_FORMS_INITIAL_DATA'
export const FETCH_TAX_FORMS_STATUS = 'FETCH_TAX_FORMS_STATUS'
export const RECEIVE_TAX_FORMS_STATUS = 'RECEIVE_TAX_FORMS_STATUS'
export const SELECT_TAX_FORMS = 'SELECT_TAX_FORMS'
export const SHOW_TAX_FORMS_LOADING = 'SHOW_TAX_FORMS_LOADING'
export const HIDE_TAX_FORMS_LOADING = 'HIDE_TAX_FORMS_LOADING'
