import {PayoutFiltersInterface} from "../store";
import {
  FetchPayoutsAction, OpenPayoutDetailsAction, ReceiveInitialDataAction,
  ReceivePayoutsAction,
  UpdateFilterAction
} from "./payouts.action";
import {
  FETCH_PAYOUTS, RECEIVE_PAYOUTS,
  FETCH_PAYOUT_INITIAL_DATA,
  HIDE_PAYOUT_LOADING, RECEIVE_PAYOUT_INITIAL_DATA,
  SHOW_PAYOUT_LOADING,
  UPDATE_PAYOUT_FILTER, OPEN_PAYOUT_DETAILS, CLOSE_PAYOUT_DETAILS
} from "../constants/payouts";
import {DatatablePayoutRequestInterface} from "../interfaces/datatable.payout.request.interface";
import {DatatableResponseInterface} from "../interfaces/datatable.response.interface";
import {CommunityInterface} from "../interfaces/community.interface";


export const updateFilter = (payload: PayoutFiltersInterface) : UpdateFilterAction => {
  return {
    type: UPDATE_PAYOUT_FILTER,
    payload
  }
}

export const fetchPayouts = (request: DatatablePayoutRequestInterface) : FetchPayoutsAction => {
  return {
    type: FETCH_PAYOUTS,
    request: request
  }
}

export const receivePayouts = (payload: DatatableResponseInterface) : ReceivePayoutsAction => {
  return {
    type: RECEIVE_PAYOUTS,
    payload
  }
}

export const fetchInitialData = {
  type: FETCH_PAYOUT_INITIAL_DATA
}

export const receiveInitialData = (communities: CommunityInterface[]) : ReceiveInitialDataAction => {
  return {
    type: RECEIVE_PAYOUT_INITIAL_DATA,
    communities
  }
}

export const showLoading = {
  type: SHOW_PAYOUT_LOADING
}

export const hideLoading = {
  type: HIDE_PAYOUT_LOADING
}

export const openPayoutDetails = (payoutId: number): OpenPayoutDetailsAction => {
  return {
    type: OPEN_PAYOUT_DETAILS,
    payoutId
  }
}

export const closePayoutDetails = {
  type: CLOSE_PAYOUT_DETAILS
}
