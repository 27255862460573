import {config} from "../config";
import {DatatableRequestInterface} from "../interfaces/datatable.request.interface";
import {DatatableResponseInterface} from "../interfaces/datatable.response.interface";
import httpService from "./HttpService";
import {getURLSearchParams} from "../utils/helpers";

const ONBOARDING_COMMUNITIES_URL = `${config.baseUrl}${config.suffixApi}${config.versionApi}/communities/all`
const ONBOARDING_LINK_URL = (communityId: number) => (`${config.baseUrl}${config.suffixApi}${config.versionApi}/communities/${communityId}/onboardingLink`);
const ONBOARDING_SUBMITTED_URL = (communityId: number) => (`${config.baseUrl}${config.suffixApi}${config.versionApi}/communities/${communityId}/onboardingSubmitted`);

export const getOnboardingCommunities = (request: DatatableRequestInterface):Promise<DatatableResponseInterface> => {
  return httpService.get<DatatableResponseInterface>(ONBOARDING_COMMUNITIES_URL, getURLSearchParams(request))
}

export const getOnboardingLink = (communityId: number):Promise<string> => {
  return httpService.get<string>(ONBOARDING_LINK_URL(communityId))
}

export const submitOnboardingApplication = (communityId: number):Promise<void> => {
  return httpService.post<void>(ONBOARDING_SUBMITTED_URL(communityId))
}
