import { config } from "../config"
import httpService from "./HttpService"
import { DatatableLockboxFileRequestInterface } from "../interfaces/lockboxfiles.datatable.request.interface";
import { DatatableResponseInterface } from "../interfaces/datatable.response.interface";
import fileDownload from "js-file-download";
import { getURLSearchParams } from "../utils/helpers";
const LOCKBOXFILES_URL = `${config.baseUrl}${config.suffixApi}${config.versionApi}/reporting/lockboxFiles`


export const getLockboxFiles = (request: DatatableLockboxFileRequestInterface):Promise<DatatableResponseInterface> => {
    return httpService.get<DatatableResponseInterface>(LOCKBOXFILES_URL,getURLSearchParams(request))
  }

export const downloadLockboxFile = (blobFileId: string) => {
  httpService.get(LOCKBOXFILES_URL+"/download", getURLSearchParams({blobFileId : blobFileId}))
    .then((data: any) => {
      var nameFileArray = blobFileId.split('/');
      var managementCompanyId = nameFileArray[0];
      var fileName = nameFileArray[2];
      fileDownload(data, `MC${managementCompanyId}_${fileName.substring(0,fileName.indexOf('.'))}.csv`);
    });
}
  