import {
  DashboardUserFiltersInterface,
  FiltersHomeownersInterface,
  FiltersInterface, FiltersOnboardingInterface, FiltersTaxFormsInterface,
  LockboxFileFiltersInterface,
  NotificationHandlerInterface,
  PayoutFiltersInterface
} from "../store";
import {
  DashboardUserDatatableRequestInterface,
  DatatableRequestInterface
} from "../interfaces/datatable.request.interface";
import {config} from '../config'
import moment from "moment";
import {HomeownersDatatableRequestInterface} from "../interfaces/homeowners.datatable.request.interface";
import {DatatablePayoutRequestInterface} from "../interfaces/datatable.payout.request.interface";
import {DatatableLockboxFileRequestInterface} from "../interfaces/lockboxfiles.datatable.request.interface";
import {OnboardingDatatableRequestInterface} from "../interfaces/onboarding.datatable.request.interface";
import {DataTableTitle} from "../components/DataTable";
import {TaxFormsDatatableRequestInterface} from "../interfaces/taxforms.datatable.request.interface";

export const convertToPaymentTransactionRequest = (filters: FiltersInterface): DatatableRequestInterface => {
  const paymentTypes: string[] = [];
  if (filters.paymentTypesSelected && filters.paymentTypesSelected.indexOf('Card') >= 0) {
    paymentTypes.push('CreditCard');
    paymentTypes.push('DebitCard');
  }
  if (filters.paymentTypesSelected && filters.paymentTypesSelected.indexOf('Ach') >= 0) {
    paymentTypes.push('Ach');
  }
  return {
    pageNumber: filters.pageNumber,
    pageSize: filters.pageSize || config.paymentTransactionsPageSize,
    communityID: filters.communitySelected ? filters.communitySelected.id : undefined,
    fromPaymentDate: moment(filters.selectedStartDate).startOf('day').format('MM/DD/YYYY HH:mm:ss'),
    toPaymentDate: moment(filters.selectedEndDate).endOf('day').format('MM/DD/YYYY HH:mm:ss'),
    paymentStatuses: filters.status,
    paymentTypes: !!paymentTypes && paymentTypes.length ? paymentTypes : undefined,
    payerID: filters.residentSelected ? filters.residentSelected.id : undefined
  }
}

export const convertToHomeownersRequest = (filters: FiltersHomeownersInterface): HomeownersDatatableRequestInterface => {
  return {
    pageNumber: filters.pageNumber,
    pageSize: filters.pageSize || config.paymentTransactionsPageSize,
    communityID: filters.communitySelected ? filters.communitySelected.id : undefined,
    name: filters.name,
    accountNumber: filters.accountNumber,
    address: filters.address,
    paymentTypes: !!filters.paymentTypesSelected && filters.paymentTypesSelected.length ? filters.paymentTypesSelected : undefined,
  }
}

export const getInitialRequest = (): DatatableRequestInterface => {
  return {
    pageNumber: 1,
    pageSize: config.paymentTransactionsPageSize,
    fromPaymentDate: moment().startOf('month').startOf('day').format('MM/DD/YYYY HH:mm:ss'),
    toPaymentDate: moment().endOf('day').format('MM/DD/YYYY HH:mm:ss'),
  }
}

export const getInitialFilters = (): FiltersInterface => {
  return {
    selectedStartDate: moment().startOf('month').toDate(),
    selectedEndDate: moment().endOf('day').toDate(),
    pageNumber: 1,
    pageSize: config.paymentTransactionsPageSize
  }
}

export const getHomeownersInitialRequest = (): DatatableRequestInterface => {
  return {
    pageNumber: 1,
    pageSize: config.paymentTransactionsPageSize
  }
}

export const getURLSearchParams = (request: any): URLSearchParams => {
  const searchParams = new URLSearchParams()
  Object.keys(request).forEach((key) => {
    if(Array.isArray( request[key] ) && request[key].length > 0) {
      request[key].forEach((item: string) => {
        if(item !== undefined && item !== null && item !== '')
          searchParams.append(key, item)
      })
    } else
    if(request[key] != null && request[key])
      searchParams.append(key, request[key])
  })
  return searchParams
}

export const getNotificationFormat = (notificationType:  'success' | 'info' | 'warning' | 'error', hintMessage: string, message?: string, modal?: boolean, code?: number, callback?: ()=>void): NotificationHandlerInterface => {
  return {
    notificationID: generateUID(),
    notificationType,
    message,
    hintMessage,
    code,
    callback,
    modal,
  }
}

const generateUID = () => {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
}

export const parseJwt = (token?: string): any => {
  if (!token) {
    return {}
  }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  
  return JSON.parse(jsonPayload);
};

export const removeInvalidCharacters = (value: string): string => {
  if(value) {
    return value.replace(/[^a-z0-9 ,.?+()*&%$@!]/ig, '')
  }
  return value;
}

export const removeInvalidCharactersAccountNumber = (value: string): string => {
  if(value) {
    return value.replace(/[^a-z0-9]/ig, '')
  }
  return value;
}

const isValidToSendRequest = (value: string | undefined): boolean => {
  if(value === undefined || value === null) {
    return true;
  }
  return value.length >= 3 || value.length === 0;
}

export const updateFilters = (newValue: string | undefined): boolean => {
  return isValidToSendRequest(newValue);
}

export const convertToCurrency = (value: number): number => {
  return parseFloat(String((value / 100)))
}

export const convertToPayoutsRequest = (filters: PayoutFiltersInterface): DatatablePayoutRequestInterface => {
  return {
    pageNumber: filters.pageNumber,
    pageSize: filters.pageSize || config.paymentTransactionsPageSize,
    communityID: filters.communitySelected ? filters.communitySelected.id : undefined,
    fromPayoutDate: moment(filters.selectedStartDate).startOf('day').format('MM/DD/YYYY HH:mm:ss'),
    toPayoutDate: moment(filters.selectedEndDate).endOf('day').format('MM/DD/YYYY HH:mm:ss'),
  }
}

export const getInitialRequestForPayouts = (): DatatablePayoutRequestInterface => {
  return {
    pageNumber: 1,
    pageSize: config.paymentTransactionsPageSize,
    fromPayoutDate: moment().startOf('month').startOf('day').format('MM/DD/YYYY HH:mm:ss'),
    toPayoutDate: moment().endOf('day').format('MM/DD/YYYY HH:mm:ss'),
  }
}

export const createHeader = (text: string, isStyled: boolean = false, hasMark: boolean = false, classColor?: 'inherit' | 'primary' | 'secondary' | 'action' | 'disabled' | 'error', centered?: boolean): DataTableTitle => {
  return { text, isStyled, hasMark, classColor, centered };
}

export const convertToDashboardUsersRequest = (filters: DashboardUserFiltersInterface): DashboardUserDatatableRequestInterface => {
  return {
    pageNumber: filters.pageNumber,
    pageSize: filters.pageSize || config.paymentTransactionsPageSize,
    managementCompanyId: filters.managementCompanySelected ?  filters.managementCompanySelected.id : undefined,
    name: filters.userEmailAddressQueryString
  }
}

export const getInitialRequestForLockboxFiles = (): DatatableLockboxFileRequestInterface => {
  return {
    pageNumber: 1,
    pageSize: config.lockboxFilesPageSize,
    fromLockboxFileDate: moment().startOf('month').startOf('day').format('MM/DD/YYYY HH:mm:ss'),
    toLockboxFileDate: moment().endOf('day').format('MM/DD/YYYY HH:mm:ss'),
  }
}

export const convertToLockboxRequest = (filters: LockboxFileFiltersInterface): DatatableLockboxFileRequestInterface => {
  return {
    pageNumber: filters.pageNumber,
    pageSize: filters.pageSize || config.lockboxFilesPageSize,
    fromLockboxFileDate: moment(filters.fromLockboxFileDate).startOf('day').format('MM/DD/YYYY HH:mm:ss'),
    toLockboxFileDate: moment(filters.toLockboxFileDate).endOf('day').format('MM/DD/YYYY HH:mm:ss'),
  }
}

export const convertToOnboardingRequest = (filters: FiltersOnboardingInterface): OnboardingDatatableRequestInterface => {
  return {
    pageNumber: filters.pageNumber,
    pageSize: filters.pageSize || config.onboardingPageSize,
    newOnboarding: true,
    managementCompanyId: !!filters.managementCompanySelected ? filters.managementCompanySelected.id : undefined,
    onboardingStatus: filters.onboardingStatusSelected
  }
}

export const getOnboardingInitialRequest = (): OnboardingDatatableRequestInterface => {
  return {
    pageNumber: 1,
    newOnboarding: true,
    pageSize: config.onboardingPageSize
  }
}

export const convertToTaxFormsRequest = (filters: FiltersTaxFormsInterface): TaxFormsDatatableRequestInterface => {
  return {
    pageNumber: filters.pageNumber,
    pageSize: filters.pageSize || config.onboardingPageSize,
    managementCompanyId: !!filters.managementCompanySelected ? filters.managementCompanySelected.id : undefined,
    communityId: !!filters.communitySelected ? filters.communitySelected.id : undefined,
    year: !!filters.yearSelected ? filters.yearSelected : (new Date().getFullYear() - 1)
  }
}

export const getTaxFormsInitialRequest = (): TaxFormsDatatableRequestInterface => {
  return {
    pageNumber: 1,
    pageSize: config.taxFormsPageSize,
    year: (new Date().getFullYear() -1)
  }
}
