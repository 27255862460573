/**
 * Combine all reducers in this file and export the combined reducers.
 */

import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';

import history from '../utils/history';
import {paymentTransactionsReducer} from "../reducers/paymentTransactionsReducer";
import {notificationHandlerReducer} from "../reducers/notificationHandlerReducer";
import {userInfoReducer} from "../reducers/userInfoReducer";
import {homeownersReducer} from "../reducers/homeownersReducer";
import {homeownerDetailsReducer} from "../reducers/homeownerDetailsReducer";
import {payoutsReducer} from "../reducers/payoutReducer";
import {dashboardUsersReducer} from "../reducers/dashboardUserReducer";
import {lockboxFileReducer} from "../reducers/lockboxFileReducer";
import {onboardingReducer} from "../reducers/onboardingReducer";
import {taxFormsReducer} from "../reducers/taxFormsReducer";
import {representativeProfileReducer} from "../reducers/representativeProfileReducer";
import {representativeUsersReducer} from "../reducers/representativeUserReducer";
import {reportsReducer} from "../reducers/reportsReducer";

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer() {
  return combineReducers({
    router: connectRouter(history),
    paymentTransaction: paymentTransactionsReducer,
    notifications: notificationHandlerReducer,
    userInfo: userInfoReducer,
    homeowner: homeownersReducer,
    homeownerDetails: homeownerDetailsReducer,
    payouts: payoutsReducer,
    dashboardUsers: dashboardUsersReducer,
    lockBoxFiles : lockboxFileReducer,
    onboarding: onboardingReducer,
    taxForms: taxFormsReducer,
    representativeProfile: representativeProfileReducer,
    representativeUsers: representativeUsersReducer,
    reports: reportsReducer
  });
}
