import {DashboardUserFiltersInterface} from "../store";
import {
  CloseDashboardUserModalAction,
  DeleteDashboardUserAction,
  FetchDashboardUserInitialDataAction,
  FetchDashboardUsersAction,
  HideDashboardUserLoadingAction,
  OpenDashboardUserModalAction,
  ReceiveDashboardUserInitialDataAction,
  ReceiveDashboardUsersAction,
  ShowDashboardUserLoadingAction,
  UpdateDashboardUserFilterAction
} from "./dashboard.user.action";
import {
  CLOSE_DASHBOARD_USER_MODAL,
  DELETE_DASHBOARD_USER,
  FETCH_DASHBOARD_USER_INITIAL_DATA,
  FETCH_DASHBOARD_USERS,
  HIDE_DASHBOARD_USER_LOADING,
  OPEN_DASHBOARD_USER_MODAL,
  RECEIVE_DASHBOARD_USER_INITIAL_DATA,
  RECEIVE_DASHBOARD_USERS,
  SHOW_DASHBOARD_USER_LOADING,
  UPDATE_DASHBOARD_USER_FILTER
} from "../constants/dashboardUserConstants";
import {DashboardUserDatatableRequestInterface} from "../interfaces/datatable.request.interface";
import {DatatableResponseInterface} from "../interfaces/datatable.response.interface";
import {UserCompanyInterface} from "../interfaces/user.company.interface";

export const updateDashboardUserFilter = (payload: DashboardUserFiltersInterface, cleanup: boolean = false) : UpdateDashboardUserFilterAction => ({
  type: UPDATE_DASHBOARD_USER_FILTER,
  payload,
  cleanup
});

export const fetchDashboardUsers = (request: DashboardUserDatatableRequestInterface) : FetchDashboardUsersAction => ({
  type: FETCH_DASHBOARD_USERS,
  request
})

export const receiveDashboardUsers = (payload: DatatableResponseInterface) : ReceiveDashboardUsersAction => ({
  type: RECEIVE_DASHBOARD_USERS,
  payload
})

export const fetchDashboardUserInitialData : FetchDashboardUserInitialDataAction = {
  type: FETCH_DASHBOARD_USER_INITIAL_DATA
}

export const receiveDashboardUserInitialData = (managementCompanies: UserCompanyInterface[]) : ReceiveDashboardUserInitialDataAction => ({
  type: RECEIVE_DASHBOARD_USER_INITIAL_DATA,
  managementCompanies
})

export const showDashboardUserLoading: ShowDashboardUserLoadingAction = {
  type: SHOW_DASHBOARD_USER_LOADING
}

export const hideDashboardUserLoading: HideDashboardUserLoadingAction = {
  type: HIDE_DASHBOARD_USER_LOADING
}

export const openDashboardUserModal = (modalType: string, dashboardUserId?: string): OpenDashboardUserModalAction => ({
  type: OPEN_DASHBOARD_USER_MODAL,
  modalType,
  dashboardUserId
})

export const closeDashboardUserModal: CloseDashboardUserModalAction = {
  type: CLOSE_DASHBOARD_USER_MODAL
}

export const deleteDashboardUser = (dashboardUserId: string, emailAddress: string, payload: DashboardUserFiltersInterface) : DeleteDashboardUserAction => ({
  type: DELETE_DASHBOARD_USER,
  dashboardUserId,
  emailAddress,
  payload
})
