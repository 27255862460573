import {DatatableResponseInterface} from "../interfaces/datatable.response.interface";
import {config} from '../config'
import {DatatableRequestInterface} from "../interfaces/datatable.request.interface";
import {CommunityInterface} from "../interfaces/community.interface";
import {ResidentInterface} from "../interfaces/resident.interface";
import {getURLSearchParams} from "../utils/helpers";
import httpService from "./HttpService";
import fileDownload from "js-file-download"
import {RefundedReasonEnum} from "../interfaces/refunded.reason.enum";
import {DatatablePayoutRequestInterface} from "../interfaces/datatable.payout.request.interface";
import {PayoutDetailsInterface} from "../interfaces/payout.details.interface";
import {PayoutInterface} from "../interfaces/payout.interface";

const PAYMENT_TRANSACTIONS_URL = `${config.baseUrl}${config.suffixApi}${config.versionApi}/reporting/payments`
const COMMUNITIES_URL = `${config.baseUrl}${config.suffixApi}${config.versionApi}/communities/?onboardingstatus=onboarded`
const RESIDENTS_URL = (companyID: number) => (`${config.baseUrl}${config.suffixApi}${config.versionApi}/communities/${companyID}/payers`)
const PAYMENT_EXPORT_URL = `${config.baseUrl}${config.suffixApi}${config.versionApi}/reporting/payments/export`
const PAYMENT_REFUND_URL = (paymentID: number) => (`${config.baseUrl}${config.suffixApi}${config.versionApi}/payments/${paymentID}/refund`);
const PAYOUTS_URL = `${config.baseUrl}${config.suffixApi}${config.versionApi}/reporting/payouts`
const PAYOUTS_EXPORT_URL = `${config.baseUrl}${config.suffixApi}${config.versionApi}/reporting/payouts/export`
const PAYOUT_DETAILS_URL = (payoutId: number) => `${config.baseUrl}${config.suffixApi}${config.versionApi}/payouts/${payoutId}/details`
const PAYOUT_PAYMENTS_EXPORT_URL = (payoutId: number) => `${config.baseUrl}${config.suffixApi}${config.versionApi}/payouts/${payoutId}/payments/export`
const PAYOUTS_RETRIEVED_URL = (payoutId: number) => `${config.baseUrl}${config.suffixApi}${config.versionApi}/payouts/${payoutId}/retrieved`
const DAILY_PAYOUT_REPORT_EXPORT_URL = `${config.baseUrl}${config.suffixApi}${config.versionApi}/reporting/download-daily-payouts`

export const getPaymentTransactions = (request: DatatableRequestInterface):Promise<DatatableResponseInterface> => {
  return httpService.get<DatatableResponseInterface>(PAYMENT_TRANSACTIONS_URL, getURLSearchParams(request))
}

export const getCommunities = (): Promise<CommunityInterface[]> => {
  return httpService.get<CommunityInterface[]>(COMMUNITIES_URL)
}

export const getResidents = (communityID: number): Promise<ResidentInterface[]> => {
  return httpService.get<ResidentInterface[]>(RESIDENTS_URL(communityID))
}

export const downloadFile = (fileName: string, params: any) => {
  httpService.get(PAYMENT_EXPORT_URL, getURLSearchParams(params))
    .then((data: any) => {
      fileDownload(data, fileName);
    });
}

export const createRefundPayment = (paymentID: number, reason: RefundedReasonEnum, email: string): Promise<void> => {
  return httpService.post(PAYMENT_REFUND_URL(paymentID), `"${reason}"`)
}

export const getPayouts = (request: DatatablePayoutRequestInterface):Promise<DatatableResponseInterface> => {
  return httpService.get<DatatableResponseInterface>(PAYOUTS_URL, getURLSearchParams(request))
}

export const downloadPayoutsFile = (fileName: string, params: any) => {
  httpService.get(PAYOUTS_EXPORT_URL, getURLSearchParams(params))
    .then((data: any) => {
      fileDownload(data, fileName);
    });
}


export const getPayoutDetails = (payoutId: number):Promise<PayoutDetailsInterface> => {
  return httpService.get<PayoutDetailsInterface>(PAYOUT_DETAILS_URL(payoutId))
}

export const downloadPayoutPaymentsFile = (fileName: string, payoutId: number) => {
  httpService.get(PAYOUT_PAYMENTS_EXPORT_URL(payoutId))
    .then((data: any) => {
      fileDownload(data, fileName);
    });
}

export const getRetrievedPayouts = (id: number):Promise<PayoutInterface[]> => {
  return httpService.get<PayoutInterface[]>(PAYOUTS_RETRIEVED_URL(id))
}

export const downloadDailyPayoutsReport = (request: DatatablePayoutRequestInterface, fileName: string):void => {
  httpService.download(DAILY_PAYOUT_REPORT_EXPORT_URL, getURLSearchParams(request))
    .then((data: any) => {
      fileDownload(data, fileName, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    });
}
