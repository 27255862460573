import {DayOfWeek, ReportFrequency, ScheduledReportInterface} from "../interfaces/scheduled.report.interface";

export class ScheduledReport implements ScheduledReportInterface {
  day: DayOfWeek;
  frequency: ReportFrequency;
  id?: number;
  name: string;
  ownerEmailAddress: string;
  statusCriteria: string;
  loading: boolean;
  
  constructor(model: ScheduledReportInterface) {
    this.day = model.day;
    this.frequency = model.frequency;
    this.id = model.id;
    this.name = model.name;
    this.ownerEmailAddress = model.ownerEmailAddress;
    this.statusCriteria = model.statusCriteria;
    this.loading = false;
  }
  
  static builder(): ScheduledReportBuilder  {
    return new ScheduledReportBuilder();
  }
}

class ScheduledReportBuilder  {
  private readonly _scheduledReport: ScheduledReport;
  constructor() {
    this._scheduledReport = {
      name: "",
      ownerEmailAddress: "",
      frequency: ReportFrequency.Daily,
      day: DayOfWeek.Friday,
      statusCriteria: "",
      loading: false,
    };
  }

  name(name: string): ScheduledReportBuilder {
    this._scheduledReport.name = name.trim();
    return this;
  }

  ownerEmailAddress(ownerEmailAddress: string): ScheduledReportBuilder {
    this._scheduledReport.ownerEmailAddress = ownerEmailAddress;
    return this;
  }

  frequency(frequency: string): ScheduledReportBuilder {
    this._scheduledReport.frequency = (ReportFrequency as any)[frequency];
    return this;
  }

  day(day: string): ScheduledReportBuilder {
    this._scheduledReport.day = (DayOfWeek as any)[day];
    return this;
  }

  statusCriteria(statuses: {value: string; label: string;}[]): ScheduledReportBuilder {
    this._scheduledReport.statusCriteria = statuses.map(x => x.value).sort().join(",");
    return this;
  }
  
  build(): ScheduledReport {
    return this._scheduledReport;
  }
}
