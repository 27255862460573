export enum ActionEnum {
  SHOW_AMOUNT_DETAILS,
  REFUND_PAYMENT,
  SEND_RECEIPT,
  NONE,
  TERMS_CONDITIONS,
  DOWNLOAD_DOCUMENTS,
  EDIT,
  DELETE
}
