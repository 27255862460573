import {config} from "../config";
import httpService from "./HttpService";
import {UserCompanyInterface} from "../interfaces/user.company.interface";
import {Auth0User} from "../models/auth0.user.model";
import {
  DashboardUserDatatableRequestInterface
} from "../interfaces/datatable.request.interface";
import {DatatableResponseInterface} from "../interfaces/datatable.response.interface";
import {getURLSearchParams} from "../utils/helpers";
import {DashboardUserInterface} from "../interfaces/dashboard.user.interface";
import {UserProductInterface} from "../interfaces/user.product.interface";

const PROPERTY_MANAGEMENT_COMPANIES_URL = (companyId: string) => `${config.baseUrl}${config.suffixApi}${config.versionApi}/managementcompanies/${companyId}`
const MANAGEMENT_COMPANIES_URL = `${config.baseUrl}${config.suffixApi}${config.versionApi}/managementcompanies`
const AUTH0_USERS_URL = `${config.baseUrl}${config.suffixApi}${config.versionApi}/dashboardUsers/users`;
const AUTH0_USER_URL = (dashboardUserId: string) => `${config.baseUrl}${config.suffixApi}${config.versionApi}/dashboardUsers/users/${dashboardUserId}`;
const AUTH_USER_LOGIN_URL = `${config.baseUrl}${config.suffixApi}${config.versionApi}/auth/login`;


export const fetchUserCompany = async (companyId: string): Promise<UserCompanyInterface> => {
  return httpService.get<UserCompanyInterface>(PROPERTY_MANAGEMENT_COMPANIES_URL(companyId))
}

export const fetchManagementCompanies = async (): Promise<UserCompanyInterface[]> => {
  return httpService.get<UserCompanyInterface[]>(MANAGEMENT_COMPANIES_URL);
}

export const createUser = (user: Auth0User): Promise<Auth0User> => {
  return httpService.post(AUTH0_USERS_URL, user);
}

export const editUser = (dashboardUserId: string, user: Auth0User): Promise<Auth0User> => {
  return httpService.put(AUTH0_USER_URL(dashboardUserId), user);
}

export const fetchDashboardUserAPI = (request: DashboardUserDatatableRequestInterface):Promise<DatatableResponseInterface> => {
  return httpService.get<DatatableResponseInterface>(AUTH0_USERS_URL, getURLSearchParams(request));
}

export const deleteDashboardUserAPI = (dashboardUserId: string):Promise<void> => {
  return httpService.delete<void>(AUTH0_USER_URL(dashboardUserId));
}

export const getDashboardUserAPI = (dashboardUserId: string):Promise<DashboardUserInterface> => {
  return httpService.get<DashboardUserInterface>(AUTH0_USER_URL(dashboardUserId));
}

export const getUserLogin = ():Promise<UserProductInterface> => {
  return httpService.get<UserProductInterface>(AUTH_USER_LOGIN_URL);
}
