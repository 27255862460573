import { all, fork, put, call, takeLatest } from 'redux-saga/effects';
import {
  DatatableRequestInterface
} from "../interfaces/datatable.request.interface";
import {getOnboardingCommunities} from "../api/OnboardingAPI";
import {DatatableResponseInterface} from "../interfaces/datatable.response.interface";
import {FetchInitialDataAction, FetchOnboardingAction, UpdateFilterAction} from "../actions/onboarding.action";
import {
  fetchOnboarding,
  receiveOnboarding,
  showLoading,
  hideLoading,
  receiveInitialDataOnboarding
} from "../actions/onboarding";
import {
  FETCH_ONBOARDING_INITIAL_DATA,
  FETCH_ONBOARDING,
  UPDATE_ONBOARDING_FILTER
} from "../constants/onboardingConstants";
import {
  convertToOnboardingRequest,
  getOnboardingInitialRequest
} from "../utils/helpers";
import {catchException} from "./errorHandlerSaga";
import {OnboardingDatatableRequestInterface} from "../interfaces/onboarding.datatable.request.interface";
import {fetchManagementCompanies} from "../api/Auth0API";

/**
 * Saga to observe FETCH_ONBOARDING action
 */
export function* watchRequestOnboardingSaga() {
  yield takeLatest(FETCH_ONBOARDING, requestOnboardingSaga)
}

/**
 * Resolver for FETCH_ONBOARDING
 * @param action
 */
function* requestOnboardingSaga(action: FetchOnboardingAction) {
  try {
    yield put(showLoading)
    const response: DatatableResponseInterface = yield call(getOnboardingCommunities, action.request)
    yield put(receiveOnboarding(response))
  } catch (e) {
    console.warn('Error in requestOnboardingSaga: ', e)
    yield catchException(e)
  } finally {
    yield put(hideLoading)
  }
  
}

/**
 * Saga to observe UPDATE_FILTER action
 */
export function* watchUpdateFiltersSaga() {
  yield takeLatest(UPDATE_ONBOARDING_FILTER, updateFilterSaga);
}

/**
 * Resolver for UPDATE_FILTER
 * @param action
 */
function* updateFilterSaga(action: UpdateFilterAction) {
  if (!action.cleanup) {
    const req: OnboardingDatatableRequestInterface = convertToOnboardingRequest(action.payload);
    yield put(fetchOnboarding(req))
  } else {
    yield put(receiveOnboarding( {
      offset: 0,
      limit: 0,
      itemCount: 0,
      items: []
    }))
  }
}

/**
 * Saga to observe FETCH_INITIAL_DATA
 */
export function* watchFetchInitialDataSaga() {
  yield takeLatest(FETCH_ONBOARDING_INITIAL_DATA, fetchInitialDataSaga);
}

/**
 * Resolve FETCH_INITIAL_DATA
 */
function* fetchInitialDataSaga(action: FetchInitialDataAction) {
  try {
    if (action.isExternalUser) {
      const managementCompanies = yield call(fetchManagementCompanies);
      yield put(receiveInitialDataOnboarding(managementCompanies));
    } else {
      const request: DatatableRequestInterface = getOnboardingInitialRequest()
      const response = yield call(getOnboardingCommunities, request)
      yield put(receiveOnboarding(response))
    }
  } catch (e) {
    console.warn('Error in fetchInitialDataSaga: ', e)
    yield catchException(e)
  }
}

export function* onboardingSagas() {
  yield all([
    fork(watchRequestOnboardingSaga),
    fork(watchUpdateFiltersSaga),
    fork(watchFetchInitialDataSaga)
  ]);
}
