import {HomeownersInterface} from "../store";
import {config} from "../config";
import {HomeownerTypes} from "../actions/homeowners.action";
import {
  HIDE_HOMEOWNERS_LOADING,
  RECEIVE_HOMEOWNERS,
  RECEIVE_HOMEOWNERS_INITIAL_DATA, SELECT_HOMEOWNER,
  SHOW_HOMEOWNERS_LOADING,
  UPDATE_HOMEOWNERS_FILTER
} from "../constants/homeowners";

export const homeownersReducer = (state: HomeownersInterface = getHomeownersInitialState(), action : HomeownerTypes): any => {
  switch (action.type) {
    case UPDATE_HOMEOWNERS_FILTER:
      return {
        ...state,
        filters: {
          ...action.payload
        }
      }
    case RECEIVE_HOMEOWNERS:
      return {
        ...state,
        dataSource: {
          ...state.dataSource,
          ...action.payload
        }
      }
    case RECEIVE_HOMEOWNERS_INITIAL_DATA:
      return {
        ...state,
        initialized: true,
        catalog: {
          ...state.catalog,
          communities: action.communities
        }
      }
    case SELECT_HOMEOWNER:
      return {
        ...state,
        homeownerSelectedId: action.id
      }
    case SHOW_HOMEOWNERS_LOADING:
      return {
        ...state,
        loading: true
      }
    case HIDE_HOMEOWNERS_LOADING:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}

const getHomeownersInitialState = (): HomeownersInterface => {
  return {
    filters: {
      pageNumber: 1,
      pageSize: config.homeownersPageSize
    },
    initialized: false,
    loading: false,
    catalog: {
      paymentTypes: [{paymentMethodTypeName: 'Future'}]
    },
    dataSource: {limit: config.homeownersPageSize, offset: 0, itemCount: 0, items: []}
  }
}
