import {
  HIDE_LOCKBOXFILE_LOADING,
    RECEIVE_LOCKBOXFILES, SHOW_LOCKBOXFILE_LOADING, UPDATE_LOCKBOX_FILTER
  } from "../constants/lockboxFiles"
import {LockboxFilesInterface} from "../store";
import moment from "moment";
import {config} from "../config";
import { LockboxFilesTypes } from "../actions/lockboxfiles.action";
  
  export const lockboxFileReducer = (state : LockboxFilesInterface = getLockboxFilesInitialState(), action : LockboxFilesTypes): any => {
    switch (action.type) {
      case RECEIVE_LOCKBOXFILES:
        return {
          ...state,
          initialized: true,
          dataSource: {
            ...state.dataSource,
            ...action.payload
          }
        }
        case UPDATE_LOCKBOX_FILTER:
          return {
            ...state,
            filters: {
              ...action.payload
            }
          }
        case SHOW_LOCKBOXFILE_LOADING:
          return {
          ...state,
          loading: true
        }
        case HIDE_LOCKBOXFILE_LOADING:
          return {
          ...state,
          loading: false
      }
      default:
        return state
    }
  }
  
  const getLockboxFilesInitialState = (): LockboxFilesInterface => {
    return {
      filters: {
        fromLockboxFileDate: moment().startOf('month').toDate(),
        toLockboxFileDate: moment().endOf('day').toDate(),
        pageNumber: 1,
        pageSize: config.lockboxFilesPageSize
      },
      initialized: false,
      loading: false,
      dataSource: {limit: config.lockboxFilesPageSize, offset: 0, itemCount: 0, items: []},
      download: false,
      blobFileId : ''
    }
  }
  