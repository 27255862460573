import {config} from "../config";
import {DatatableRequestInterface} from "../interfaces/datatable.request.interface";
import {DatatableResponseInterface} from "../interfaces/datatable.response.interface";
import httpService from "./HttpService";
import {getURLSearchParams} from "../utils/helpers";
import {CommunityInterface} from "../interfaces/community.interface";
import fileDownload from "js-file-download"

const COMMUNITIES_DATATABLE_URL = `${config.baseUrl}${config.suffixApi}${config.versionApi}/communities/all`
const COMMUNITIES_URL = (companyId: number) => `${config.baseUrl}${config.suffixApi}${config.versionApi}/managementcompanies/${companyId}/communities`
const DOWNLOAD_TAX_FORM = (communityId: number) => `${config.baseUrl}${config.suffixApi}${config.versionApi}/communities/${communityId}/taxformdownload`
const GET_DOCUMENTS_INFORMATION = `${config.baseUrl}${config.suffixApi}${config.versionApi}/documents/getInformation`
const DOWNLOAD_ALL_TAX_FORM = `${config.baseUrl}${config.suffixApi}${config.versionApi}/documents/downloadAll`
const CREATE_DOWNLOAD_ALL_REQUEST = `${config.baseUrl}${config.suffixApi}${config.versionApi}/documents/createDownloadRequest`
const GET_DOWNLOAD_HISTORY_REQUEST = `${config.baseUrl}${config.suffixApi}${config.versionApi}/downloadhistories`


export const getCommunitiesDatatable = (request: DatatableRequestInterface):Promise<DatatableResponseInterface> => {
  return httpService.get<DatatableResponseInterface>(COMMUNITIES_DATATABLE_URL, getURLSearchParams(request))
}

export const getCommunities = async (managementCompanyId: number): Promise<CommunityInterface[]> => {
  return httpService.get<CommunityInterface[]>(COMMUNITIES_URL(managementCompanyId));
}

export const downloadFile = async (fileName: string, communityId: number, year: number): Promise<void> => {
  const data = await httpService.download(DOWNLOAD_TAX_FORM(communityId), getURLSearchParams({year}));
  fileDownload(data, fileName, 'application/pdf');
}

export const getDocumentsInformation = async (managementCompanyId: number, year: number): Promise<any> => {
  return httpService.get<any>(GET_DOCUMENTS_INFORMATION, getURLSearchParams({managementCompanyId, year}));
}

export const downloadAllFile = async (managementCompanyId: number, year: number): Promise<void> => {
  const data = await httpService.download(DOWNLOAD_ALL_TAX_FORM, getURLSearchParams({managementCompanyId, year}));
  fileDownload(data, `taxForms_AllFiles_${year}.zip`, 'application/zip');
}

export const createDownloadAllRequest = (managementCompanyId: number, year: number):Promise<void> => {
  return httpService.post<void>(CREATE_DOWNLOAD_ALL_REQUEST, {managementCompanyId, year})
}

export const getDownloadHistories = async (communityId: number, year: number): Promise<any> => {
  return httpService.get<any>(GET_DOWNLOAD_HISTORY_REQUEST, getURLSearchParams({communityId, year}));
}
