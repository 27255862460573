import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import {DatatablePayoutRequestInterface} from "../interfaces/datatable.payout.request.interface";
import {
  getCommunities,
  getPayouts,
} from "../api/PaymentTransactionAPI";
import {DatatableResponseInterface} from "../interfaces/datatable.response.interface";
import {
  convertToPayoutsRequest,
  getInitialRequestForPayouts,
} from "../utils/helpers";
import {catchException} from "./errorHandlerSaga";
import {FETCH_PAYOUT_INITIAL_DATA, FETCH_PAYOUTS, UPDATE_PAYOUT_FILTER} from "../constants/payouts";
import {FetchPayoutsAction, UpdateFilterAction} from "../actions/payouts.action";
import {receivePayouts, fetchPayouts, showLoading, hideLoading, receiveInitialData} from "../actions/payouts";

/**
 * Saga to observe FETCH_PAYOUTS action
 */
export function* watchRequestPayoutsSaga() {
  yield takeLatest(FETCH_PAYOUTS, requestPayoutsSaga)
}

/**
 * Resolver for FETCH_PAYOUTS
 * @param action
 */
function* requestPayoutsSaga(action: FetchPayoutsAction) {
  try {
    yield put(showLoading)
    const response: DatatableResponseInterface = yield call(getPayouts, action.request)
    yield put(receivePayouts(response))
  } catch (e) {
    console.warn('Error in requestPayoutsSaga: ', e)
    yield catchException(e)
  } finally {
    yield put(hideLoading)
  }
  
}

/**
 * Saga to observe UPDATE_PAYOUT_FILTER action
 */
export function* watchUpdateFiltersSaga() {
  yield takeLatest(UPDATE_PAYOUT_FILTER, updateFilterSaga);
}

/**
 * Resolver for UPDATE_PAYOUT_FILTER
 * @param action
 */
function* updateFilterSaga(action: UpdateFilterAction) {
  const request: DatatablePayoutRequestInterface = convertToPayoutsRequest(action.payload);
  yield put(fetchPayouts(request));
}

/**
 * Saga to observe FETCH_PAYOUT_INITIAL_DATA
 */
export function* watchFetchInitialDateSaga() {
  yield takeLatest(FETCH_PAYOUT_INITIAL_DATA, fetchInitialDataSaga);
}

/**
 * Resolve FETCH_PAYOUT_INITIAL_DATA
 */
function* fetchInitialDataSaga() {
  try {
    const communities = yield call(getCommunities);
    yield put(receiveInitialData(communities))
    const request: DatatablePayoutRequestInterface = getInitialRequestForPayouts()
    const response = yield call(getPayouts, request)
    yield put(receivePayouts(response))
  } catch (e) {
    console.warn('Error in fetchInitialDataSaga: ', e)
    yield catchException(e)
  }
}

export function* payoutsSagas() {
  yield all([
    fork(watchUpdateFiltersSaga),
    fork(watchFetchInitialDateSaga),
    fork(watchRequestPayoutsSaga)
  ]);
}
