import {RepresentativeProfileInterface} from "../store";
import {RepresentativeProfileTypes} from "../actions/representative.profile.action";
import {
  HIDE_REPRESENTATIVE_PROFILE_LOADING,
  RECEIVE_REPRESENTATIVE_PROFILE,
  SHOW_PAGE_UNAVAILABLE_ERROR,
  SHOW_REPRESENTATIVE_PROFILE_CONFIRMATION,
  SHOW_REPRESENTATIVE_PROFILE_ERROR,
  SHOW_REPRESENTATIVE_PROFILE_LOADING
} from "../constants/representativeProfileConstants";

export const representativeProfileReducer = (state: RepresentativeProfileInterface = getRepresentativeProfileInitialState(), action: RepresentativeProfileTypes): RepresentativeProfileInterface => {
  switch (action.type) {
    case RECEIVE_REPRESENTATIVE_PROFILE:
      return {
        ...state,
        initialized: true,
        representative: {
          ...action.representative
        }
      }
    case SHOW_REPRESENTATIVE_PROFILE_LOADING: {
      return {
        ...state,
        loading: true,
        showError: false
      }
    }
    case HIDE_REPRESENTATIVE_PROFILE_LOADING: {
      return {
        ...state,
        loading: false
      }
    }
    case SHOW_REPRESENTATIVE_PROFILE_CONFIRMATION:
      return {
        ...state,
        showConfirmation: true,
        showError: false
      }
    case SHOW_PAGE_UNAVAILABLE_ERROR:
      return {
        ...state,
        pageUnavailable: true,
        showError: false
      }
    case SHOW_REPRESENTATIVE_PROFILE_ERROR:
      return {
        ...state,
        initialized: true,
        showError: true,
        errorMessage: action.errorMessage
      }
    default:
      return state
  }
}

const getRepresentativeProfileInitialState = (): RepresentativeProfileInterface => {
  return {
    initialized: false,
    loading: false,
    showError: false,
    showConfirmation: false,
    pageUnavailable: false
  }
}
