import * as React from "react";
import { Route } from "react-router-dom";
import { useAuth0 } from "../Auth";
import {RouteComponentProps, RouteProps} from "react-router";
import {config} from "../config";
import * as QueryString from "query-string";

interface PrivateRouteOptions {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

const PrivateRoute = ({ component: Component, path, location, ...rest }: PrivateRouteOptions & RouteProps) => {
  const { loading, isAuthenticated, loginWithRedirect, userProduct } = useAuth0();
  React.useEffect(() => {
    if (!!location) {
      const queryString = QueryString.parse(location.search);
      
      if (!!queryString.reportId) {
        localStorage.setItem("reportId", queryString.reportId.toString());
      }
      
      if (!!queryString.accessToken) {
        sessionStorage.setItem("suiteManagerToken", queryString.accessToken.toString());
      }
      
    }
    
    if (loading || isAuthenticated) {
      return;
    }
    const redirect = async () => {
      await loginWithRedirect({
        appState: {targetUrl: config.redirectUri}
      });
    };
    redirect();
  }, [loading, isAuthenticated, loginWithRedirect, path]);
  
  const render = (props: any) =>
    isAuthenticated ? <Component {...props} /> : null;
  
  return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;
