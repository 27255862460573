/**
 * Payouts Actions Constants
 *
 * Constants to handle the actions during the Redux process
 *
 */

export const UPDATE_PAYOUT_FILTER = 'UPDATE_PAYOUT_FILTER'
export const FETCH_PAYOUTS = 'FETCH_PAYOUTS'
export const RECEIVE_PAYOUTS = 'RECEIVE_PAYOUTS'
export const RECEIVE_PAYOUT_INITIAL_DATA = 'RECEIVE_PAYOUT_INITIAL_DATA'
export const FETCH_PAYOUT_INITIAL_DATA = 'FETCH_PAYOUT_INITIAL_DATA'
export const SHOW_PAYOUT_LOADING = 'SHOW_PAYOUT_LOADING'
export const HIDE_PAYOUT_LOADING = 'HIDE_PAYOUT_LOADING'
export const PAYOUT_INITIALIZED = 'PAYOUT_INITIALIZED'
export const OPEN_PAYOUT_DETAILS = 'OPEN_PAYOUT_DETAILS'
export const CLOSE_PAYOUT_DETAILS = 'CLOSE_PAYOUT_DETAILS'
