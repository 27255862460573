/**
 * REPRESENTATIVE User Actions Constants
 *
 * Constants to handle the actions during the Redux process
 *
 */

export const FETCH_REPRESENTATIVE_USERS = 'FETCH_REPRESENTATIVE_USERS'
export const RECEIVE_REPRESENTATIVE_USERS = 'RECEIVE_REPRESENTATIVE_USERS'
export const RECEIVE_REPRESENTATIVE_USER_INITIAL_DATA = 'RECEIVE_REPRESENTATIVE_USER_INITIAL_DATA'
export const FETCH_REPRESENTATIVE_USER_INITIAL_DATA = 'FETCH_REPRESENTATIVE_USER_INITIAL_DATA'
export const SHOW_REPRESENTATIVE_USER_LOADING = 'SHOW_REPRESENTATIVE_USER_LOADING'
export const HIDE_REPRESENTATIVE_USER_LOADING = 'HIDE_REPRESENTATIVE_USER_LOADING'
export const REPRESENTATIVE_USER_INITIALIZED = 'REPRESENTATIVE_USER_INITIALIZED'
export const OPEN_REPRESENTATIVE_USER_MODAL = 'OPEN_REPRESENTATIVE_USER_MODAL'
export const CLOSE_REPRESENTATIVE_USER_MODAL = 'CLOSE_REPRESENTATIVE_USER_MODAL'
