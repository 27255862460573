import {
  CLOSE_REFUND_PAYMENT_MODAL, CLOSE_SEND_RECEIPT_MODAL,
  HIDE_LOADING,
  INITIALIZED,
  OPEN_REFUND_PAYMENT_MODAL, OPEN_SEND_RECEIPT_MODAL,
  RECEIVE_INITIAL_DATA,
  RECEIVE_PAYMENT_TRANSACTIONS,
  RECEIVE_RESIDENTS,
  REFUND_PAYMENT,
  SELECT_PAYMENT,
  SHOW_LOADING,
  UPDATE_FILTER
} from "../constants/paymentTransactions"
import {PaymentTransactionInterface} from "../store";
import {
  PaymentTransactionTypes,
  ReceiveResidentsAction,
  RefundPaymentAction,
  SelectPaymentAction
} from "../actions/payment.transaction.action";
import moment from "moment";
import {config} from "../config";
import {TransactionInterface} from "../interfaces/transaction.interface";

export const paymentTransactionsReducer = (state : PaymentTransactionInterface = getPaymentTransactionInitialState(), action : PaymentTransactionTypes): any => {
  switch (action.type) {
    case UPDATE_FILTER:
      return {
        ...state,
        filters: {
          ...action.payload
        }
      }
    case RECEIVE_PAYMENT_TRANSACTIONS:
      return {
        ...state,
        dataSource: {
          ...state.dataSource,
          ...action.payload
        }
      }
    case RECEIVE_INITIAL_DATA:
      return {
        ...state,
        initialized: true,
        catalog: {
          communities: action.communities,
          paymentTypes: action.paymentTypes
        }
      }
    case RECEIVE_RESIDENTS:
      action = action as ReceiveResidentsAction
      return {
        ...state,
        catalog: {
          ...state.catalog,
          residents: action.residents
        }
      }
    case REFUND_PAYMENT:
      const actionRefundPayment = action as RefundPaymentAction;
      return {
        ...state,
        datasource: {
          ...state.dataSource,
          items: state.dataSource.items.map(x => {
            const t = x as TransactionInterface;
            if (t.id === actionRefundPayment.paymentID) {
              t.paymentStatus = 'Refund pending'
              t.refundReason = actionRefundPayment.reason
              t.refundedBy = "temp"
            }
            return t;
          })
        }
      }
    case SELECT_PAYMENT:
      const actionSelectPayment = action as SelectPaymentAction;
      return {
        ...state,
        paymentSelected: actionSelectPayment.payment,
      }
    case SHOW_LOADING:
      return {
        ...state,
        loading: true
      }
    case HIDE_LOADING:
      return {
        ...state,
        loading: false
      }
    case OPEN_REFUND_PAYMENT_MODAL:
      return {
        ...state,
        showRefundPaymentModal: true
      }
    case CLOSE_REFUND_PAYMENT_MODAL:
      return {
        ...state,
        showRefundPaymentModal: false
      }
    case OPEN_SEND_RECEIPT_MODAL:
      return {
        ...state,
        showSendReceiptModal: true
      }
    case CLOSE_SEND_RECEIPT_MODAL:
      return {
        ...state,
        showSendReceiptModal: false
      }
    case INITIALIZED:
      return {
        ...state,
        initialized: true
      }
    default:
      return state
  }
}

const getPaymentTransactionInitialState = (): PaymentTransactionInterface => {
  return {
    filters: {
      selectedStartDate: moment().startOf('month').toDate(),
      selectedEndDate: moment().endOf('day').toDate(),
      pageNumber: 1,
      pageSize: config.paymentTransactionsPageSize
    },
    initialized: false,
    loading: false,
    catalog: {},
    dataSource: {limit: config.paymentTransactionsPageSize, offset: 0, itemCount: 0, items: []}
  }
}
