import * as React from "react";
import {Button, CircularProgress, Grid, Theme} from "@material-ui/core";
import FrontStepsLogo from "../assets/images/frontsteps-logo.png";
import {useAuth0} from "../Auth";
import {createStyles, makeStyles} from "@material-ui/styles";
import {connect, ConnectedProps} from "react-redux";
import {ApplicationState} from "../store";
import * as QueryString from "query-string";
import history from "../utils/history";
import {config} from "../config";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }),
);

const Login = (props: LoginProps) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0()
  const classes = useStyles()
  const [loading, setLoading] = React.useState(false);
  const queryString = QueryString.parse(location.search);
  
  React.useEffect(()=> {
    if(isAuthenticated) {
      history.push('/Home')
    } else {
      const redirectToLogin = async () => {
        await loginWithRedirect({
          appState: {targetUrl: config.redirectUri}
        });
      };
      redirectToLogin();
    }
  })
  
  const loginWithSuiteManager = () => {
    if (!!queryString.accessToken) {
      sessionStorage.setItem("suiteManagerToken", queryString.accessToken.toString());
    }
    if (!!sessionStorage.getItem("suiteManagerToken")) {
      history.push('/Home');
    } else {
      alert('Invalid Suite Manager Token');
    }
  }
  
  return (
    <Grid container className="mt-5 pt-5">
      <Grid container item justifyContent="center" direction="column" alignItems="center" className="h-75">
        <img src={FrontStepsLogo} alt="FRONTSTEPS Payments" className="img-fluid max-w-100 m-4"/>
        <h3 className="font-size-lg font-weight-bold mt-3">FRONTSTEPS Payments</h3>
        {!isAuthenticated && (
          <div>
            <p className="font-size-md mt-4">Login to your community</p>
            <div className={classes.wrapper}>
              <Button onClick={() => {setLoading(true); return loginWithRedirect({})}}
                      variant="contained"
                      color="primary"
                      fullWidth={true}
                      className="max-w-300"
                      disabled={loading}>
                Login with Auth0
              </Button>
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
            <div className={classes.wrapper}>
              <Button onClick={() => {
                setLoading(true);
                return loginWithSuiteManager();
              }}
                      variant="contained"
                      color="default"
                      fullWidth={true}
                      className="max-w-300"
                      disabled={loading}>
                Login with SuiteManager
              </Button>
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          </div>
        )}
      </Grid>
    </Grid>
  )
};

const mapStateToProps = ({router}: ApplicationState) => ({
  location: router.location
})

const connector = connect(mapStateToProps)

type LoginProps = ConnectedProps<typeof connector>

export default connector( Login );
