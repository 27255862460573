import {ReportsInterface} from "../store";
import {ReceiveReportsResidentsAction, ReportsTypes} from "../actions/reports.action";
import {
  HIDE_REPORTS_LOADING,
  RECEIVE_REPORTS_INITIAL_DATA, RECEIVE_REPORTS_RESIDENTS, SHOW_REPORTS_LOADING, UPDATE_REPORTS_FILTER,
} from "../constants/reportsConstants";
import moment from "moment/moment";

export const reportsReducer = (state: ReportsInterface = getReportsInitialState(), action : ReportsTypes): any => {
  switch (action.type) {
    case RECEIVE_REPORTS_INITIAL_DATA:
      return {
        ...state,
        initialized: true,
        catalog: {
          ...state.catalog,
          communities: action.communities,
        }
      }
    case RECEIVE_REPORTS_RESIDENTS:
      action = action as ReceiveReportsResidentsAction
      return {
        ...state,
        catalog: {
          ...state.catalog,
          residents: action.residents
        }
      }
    case UPDATE_REPORTS_FILTER:
      return {
        ...state,
        filters: {
          ...action.payload
        }
      }
    case SHOW_REPORTS_LOADING:
      return {
        ...state,
        loading: true
      }
    case HIDE_REPORTS_LOADING:
      return {
        ...state,
        loading: false
      }
    default:
      return state;
  }
}

const getReportsInitialState = (): ReportsInterface => {
  return {
    filters: {
      selectedStartDate: moment().startOf('month').toDate(),
      selectedEndDate: moment().endOf('day').toDate()
    },
    catalog: {},
    initialized: false,
    loading: false
  }
}
