import * as React from 'react';
import {useState} from 'react';
import {Redirect, Route, Switch} from 'react-router';
import Layout from './components/Layout';
import PaymentTransaction from "./containers/PaymentTransaction/Loadable";
import {connect, ConnectedProps} from "react-redux";
import {useAuth0} from "./Auth";
import PrivateRoute from "./components/PrivateRoute";
import Login from "./components/Login";
import {CircularProgress} from "@material-ui/core";
import {receiveUserCompany, receiveUserInfo} from "./actions/userInfo";
import {parseJwt} from "./utils/helpers";
import Homeowners from "./containers/Homeowners/Loadable";
import {AppInsightsContext} from "@microsoft/applicationinsights-react-js";
import {reactPlugin} from "./AppInsights";
import FaqComponent from "./components/FaqComponent";
import Payouts from './containers/Payouts/Loadable';
import LockboxFile from './containers/LockboxFiles/Loadable';
import Auth0Users from "./containers/Auth0Users/Loadable";
import Onboarding from "./containers/Onboarding/Loadable";
import OnboardingSuccess from "./containers/Onboarding/components/OnboardingSuccess";
import TaxForms from './containers/TaxForms/Loadable';
import RepresentativeProfile from "./containers/RepresentativeProfile/Loadable";
import RepresentativeUsers from "./containers/RepresentativeUsers/Loadable";
import Reports from "./containers/Reports/Loadable";
import {UserInterface} from "./interfaces/user.interface";
import {getGlobalControls} from "./api/AppControlsAPI";
import {UserRole} from "./interfaces/user.product.interface";
import {fetchUserCompany} from "./api/Auth0API";

const App = (props: AppProps) => {
  const { loading, isAuthenticated, getTokenSilently, user, userProduct } = useAuth0();
  const [userInfo, setUserInfo] = useState<UserInterface>();
  const [ loadingUser, setLoadingUser ] = useState(false);
  
  React.useEffect(()=> {
    let userInfoTemp : UserInterface = {
      name: '',
      email: '',
      isFSEmployee: false,
      isReportsUser: false,
      isOnboardUser: false,
      globalReportsEnabled: false
    };
    
    if(isAuthenticated && !userInfo) {
      setLoadingUser(true);
      if (user) {
        getTokenSilently().then((token) => {
          if(token) {
            const parsedToken = parseJwt(token);
            const isFSEmployeeKey = Object.keys(parsedToken).filter(key => key.indexOf('IsFSEmployee') >= 0)
            const companyIds = Object.keys(parsedToken).filter(key => key.indexOf('CompanyId') >= 0)
            const onboardUserKey = Object.keys(parsedToken).filter(key => key.indexOf('OnboardUser') >= 0)
            const fullAccessProfileKey = Object.keys(parsedToken).filter(key => key.indexOf('FullAccessProfile') >= 0)
            const isReportUserKey = Object.keys(parsedToken).filter(key => key.indexOf('ReportsUser') >= 0)
            
            const onboardUser = !!onboardUserKey && onboardUserKey.length > 0 ? !!parsedToken[onboardUserKey[0]] : false;
            const fullAccessProfile = !!fullAccessProfileKey && fullAccessProfileKey.length > 0 ? !!parsedToken[fullAccessProfileKey[0]] : false;
            const isFSEmployee = !!isFSEmployeeKey && isFSEmployeeKey.length > 0 ? !!parsedToken[isFSEmployeeKey[0]] : false;
            const isReportsUser = !!isReportUserKey && isReportUserKey.length > 0 ? !!parsedToken[isReportUserKey[0]] : false;
            
            if(!isFSEmployee && companyIds && companyIds.length > 0) {
              userInfoTemp = {
                name: user.name,
                email: user.email,
                isFSEmployee,
                isOnboardUser: onboardUser,
                fullAccessProfile,
                isReportsUser
              };
              fetchUserCompany(parsedToken[companyIds[0]]).then((company) => {
                userInfoTemp.userCompany = company;
                
                getGlobalControls().then((conf) => {
                  userInfoTemp.globalReportsEnabled = conf.reportsEnabled;
                  props.dispatch(receiveUserCompany(company, userInfoTemp));
                  sessionStorage.setItem('auth_token', token || '')
                  setUserInfo(userInfoTemp);
                  setLoadingUser(false);
                });
              });
            } else {
              userInfoTemp = {
                name: user.name,
                email: user.email,
                isFSEmployee
              };
              
              getGlobalControls().then((conf) => {
                userInfoTemp.globalReportsEnabled = conf.reportsEnabled;
                props.dispatch(receiveUserInfo(userInfoTemp))
                sessionStorage.setItem('auth_token', token || '')
                setUserInfo(userInfoTemp);
                setLoadingUser(false);
              });
              
            }
          }
        });
      } else if (userProduct && userProduct.paymentsUser) {
        
        const onboardUser = userProduct.paymentsUser.roles.findIndex(x => x === UserRole.OnboardUser) >= 0;
        const fullAccessProfile = userProduct.paymentsUser.roles.findIndex(x => x === UserRole.FullAccess) >= 0;
        const isReportsUser = userProduct.paymentsUser.roles.findIndex(x => x === UserRole.ReportsUser) >= 0;
        
        userInfoTemp = {
          name: userProduct.firstName || userProduct.lastName ? `${userProduct.firstName} ${userProduct.lastName}` : userProduct.email,
          email: userProduct.email,
          isFSEmployee: false,
          isOnboardUser: onboardUser,
          fullAccessProfile,
          isReportsUser
        };
        
        const userData = {
          "name": userProduct.firstName || userProduct.lastName ? `${userProduct.firstName} ${userProduct.lastName}` : userProduct.email,
          "email": userProduct.email,
          "companyId": userProduct.paymentsUser.mcId
        }
        
        sessionStorage.setItem('userData', JSON.stringify(userData));
        
        fetchUserCompany(String(userProduct.paymentsUser.mcId)).then((company) => {
          userInfoTemp.userCompany = company;
          getGlobalControls().then((conf) => {
            userInfoTemp.globalReportsEnabled = conf.reportsEnabled;
            props.dispatch(receiveUserCompany(company, userInfoTemp))
            setUserInfo(userInfoTemp);
            setLoadingUser(false);
          });
        });
      }
    }
  }, [isAuthenticated, user, userProduct, userInfo]);
  
  if (loading || loadingUser) {
    return (
      <div className="mt-5">
        <div className="text-center">
          <CircularProgress size={70} disableShrink />
        </div>
      </div>
    )
  }
  
  if (userInfo?.globalReportsEnabled) {
    if (userInfo.isReportsUser) {
      if (userInfo.userCompany?.reportsEnabled) {
        return (
          <AppInsightsContext.Provider value={reactPlugin}>
            <Layout>
              <Switch>
                <Route exact path="/" component={Login} />
                <Redirect exact from="/Home" to="/Reports"/>
                <Redirect exact from="/PaymentTransaction" to="/Reports"/>
                <Redirect exact from="/Payments" to="/Reports"/>
                <PrivateRoute path="/Reports" component={Reports}/>
              </Switch>
            </Layout>
          </AppInsightsContext.Provider>
        );
      } else {
        return <h2>This page has been removed by your PMC.</h2>;
      }
    }
  }
  
  
  if (userInfo?.isOnboardUser) {
    return (
      <AppInsightsContext.Provider value={reactPlugin}>
        <Layout>
          <Switch>
            <Route exact path="/" component={Login} />
            <Redirect exact from="/Home" to="/Onboarding"/>
            <Redirect exact from="/PaymentTransaction" to="/Onboarding"/>
            <Redirect exact from="/Payments" to="/Onboarding"/>
            <PrivateRoute path="/Onboarding" component={Onboarding}/>
            <PrivateRoute path="/OnboardingSuccess" component={OnboardingSuccess}/>
            <PrivateRoute path="/TaxForms" component={TaxForms}/>
          </Switch>
        </Layout>
      </AppInsightsContext.Provider>
    );
  }
  
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <Layout>
        <Switch>
          <Route exact path="/" component={Login} />
          {userInfo?.isOnboardUser ?
            <Redirect exact from="/Home" to="/Onboarding"/> :
            (userInfo?.globalReportsEnabled && userInfo?.isReportsUser && userInfo?.userCompany?.reportsEnabled ?
              <Redirect exact from="/Home" to="/Reports"/> :
              <Redirect exact from="/Home" to="/Payments"/> )
          }
          <Redirect exact from="/PaymentTransaction" to="/Payments"/>
          <PrivateRoute path="/Payments" component={PaymentTransaction}/>
          <PrivateRoute path="/Homeowners" component={Homeowners}/>
          <PrivateRoute path="/FAQ" component={FaqComponent}/>
          <PrivateRoute path="/Payouts" component={Payouts}/>
          <PrivateRoute path="/Payments/ScheduleReports" component={PaymentTransaction}/>
          <PrivateRoute path="/DashboardUser" component={Auth0Users}/>
          <PrivateRoute path="/LockboxFiles" component={LockboxFile}/>
          <PrivateRoute path="/Onboarding" component={Onboarding}/>
          <PrivateRoute path="/OnboardingSuccess" component={OnboardingSuccess}/>
          <PrivateRoute path="/TaxForms" component={TaxForms}/>
          <PrivateRoute path="/Representative" component={RepresentativeUsers}/>
          {userInfo?.globalReportsEnabled && (userInfo?.isFSEmployee || userInfo?.isReportsUser) ? <PrivateRoute path="/Reports" component={Reports}/> : null }
          <Route exact path="/Profile" component={RepresentativeProfile} />
        </Switch>
      </Layout>
    </AppInsightsContext.Provider>
  )
  
};

const connector = connect();

type AppProps = ConnectedProps<typeof connector>

export default connector(App)
