import {FiltersTaxFormsInterface} from "../store";
import {
  FetchTaxFormsAction,
  ReceiveTaxFormsAction,
  ReceiveInitialDataAction, SelectTaxFormsAction,
  UpdateFilterAction, FetchInitialDataAction, FetchTaxFormStatusAction, ReceiveTaxFormsStatusAction
} from './tax.forms.action';
import {
  FETCH_TAX_FORMS,
  FETCH_TAX_FORMS_INITIAL_DATA, FETCH_TAX_FORMS_STATUS,
  HIDE_TAX_FORMS_LOADING,
  RECEIVE_TAX_FORMS,
  RECEIVE_TAX_FORMS_INITIAL_DATA, RECEIVE_TAX_FORMS_STATUS,
  SELECT_TAX_FORMS,
  SHOW_TAX_FORMS_LOADING,
  UPDATE_TAX_FORMS_FILTER
} from "../constants/taxFormsConstants";
import {DatatableResponseInterface} from "../interfaces/datatable.response.interface";
import {UserCompanyInterface} from "../interfaces/user.company.interface";
import {TaxFormsDatatableRequestInterface} from "../interfaces/taxforms.datatable.request.interface";
import {CommunityInterface} from "../interfaces/community.interface";
import {DocumentInterface} from "../interfaces/document.interface";

export const updateFilterTaxForms = (payload: FiltersTaxFormsInterface, cleanup: boolean = false) : UpdateFilterAction => {
  return {
    type: UPDATE_TAX_FORMS_FILTER,
    payload,
    cleanup
  }
}

export const fetchTaxForms = (request: TaxFormsDatatableRequestInterface) : FetchTaxFormsAction => {
  return {
    type: FETCH_TAX_FORMS,
    request: request
  }
}

export const receiveTaxForms = (payload: DatatableResponseInterface, firstDocument?: DocumentInterface) : ReceiveTaxFormsAction => {
  return {
    type: RECEIVE_TAX_FORMS,
    payload,
    firstDocument
  }
}

export const receiveTaxFormsStatus = (firstDocument: DocumentInterface) : ReceiveTaxFormsStatusAction => {
  return {
    type: RECEIVE_TAX_FORMS_STATUS,
    firstDocument
  }
}

export const fetchInitialDataTaxForms = (isFSEmployee: boolean): FetchInitialDataAction => ({
  type: FETCH_TAX_FORMS_INITIAL_DATA,
  isFSEmployee
})

export const receiveInitialDataTaxForms = (managementCompanies: UserCompanyInterface[], communities: CommunityInterface[]) : ReceiveInitialDataAction => ({
  type: RECEIVE_TAX_FORMS_INITIAL_DATA,
  managementCompanies,
  communities
})

export const selectTaxForms = (id: number) : SelectTaxFormsAction => {
  return {
    type: SELECT_TAX_FORMS,
    id
  }
}

export const fetchTaxFormsStatus = (): FetchTaxFormStatusAction => ({
  type: FETCH_TAX_FORMS_STATUS,
})

export const showLoading = {
  type: SHOW_TAX_FORMS_LOADING
}

export const hideLoading = {
  type: HIDE_TAX_FORMS_LOADING
}
