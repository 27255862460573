import {config} from '../config'
import httpService from "./HttpService";
import RepresentativeInterface from "../interfaces/representative.interface";
import {getURLSearchParams} from "../utils/helpers";

const REPRESENTATIVE_URL = `${config.baseUrl}${config.suffixApi}${config.versionApi}/representatives/profile`;
const UPDATE_REPRESENTATIVE_URL = `${config.baseUrl}${config.suffixApi}${config.versionApi}/representatives`;
const INVITE_REPRESENTATIVE_URL = `${config.baseUrl}${config.suffixApi}${config.versionApi}/representatives/invite`;
const GET_REPRESENTATIVE_USERS_URL = (managementCompanyId: number) => `${config.baseUrl}${config.suffixApi}${config.versionApi}/representatives/users/${managementCompanyId}`;

export const getRepresentative = ():Promise<RepresentativeInterface> => {
  const applicationContext = localStorage.getItem("applicationContext");
  return httpService.get<RepresentativeInterface>(REPRESENTATIVE_URL, getURLSearchParams({applicationContext}))
}
export const updateRepresentative = (representative: RepresentativeInterface): Promise<void> => {
  const applicationContext = localStorage.getItem("applicationContext");
  return httpService.put(UPDATE_REPRESENTATIVE_URL, representative, getURLSearchParams({applicationContext}));
}

export const getRepresentatives = (managementCompanyId: number):Promise<RepresentativeInterface> => {
  return httpService.get<RepresentativeInterface>(GET_REPRESENTATIVE_USERS_URL(managementCompanyId));
}

export const sendRepresentativeInvite = (emailId: string, managementCompanyId: number):Promise<void> => {
  return httpService.post<void>(INVITE_REPRESENTATIVE_URL, {emailId, managementCompanyId});
}
