import {HomeownerDetailsInterface} from "../store";
import {HomeownerDetailsTypes, TogglePayerAllowPaymentsAction} from "../actions/homeowner.details.action";
import {
  CLOSE_HOMEOWNER_DETAILS,
  HIDE_HOMEOWNER_DETAILS_LOADING,
  OPEN_HOMEOWNER_DETAILS,
  RECEIVE_HOMEOWNER_DETAILS,
  RECEIVE_PAYMENTS_SCHEDULED,
  SHOW_HOMEOWNER_DETAILS_LOADING, TOGGLE_PAYER_ALLOW_PAYMENTS
} from "../constants/homeownerDetails";

export const homeownerDetailsReducer = (state: HomeownerDetailsInterface = getHomeownerDetailsInitialState(), action: HomeownerDetailsTypes): HomeownerDetailsInterface => {
  switch (action.type) {
    case RECEIVE_HOMEOWNER_DETAILS:
      return {
        ...state,
        initialized: true,
        payerDetails: {
          ...action.payerDetails
        },
        dataSource: {
          ...action.dataSource
        },
        transactions: {
          ...action.transactions
        }
      }
    case RECEIVE_PAYMENTS_SCHEDULED:
      return {
       ...state,
       dataSource: {
         ...action.dataSource
       }
      }
    case SHOW_HOMEOWNER_DETAILS_LOADING: {
      return {
        ...state,
        loading: true
      }
    }
    case HIDE_HOMEOWNER_DETAILS_LOADING: {
      return {
        ...state,
        loading: false
      }
    }
    case OPEN_HOMEOWNER_DETAILS:
      return {
        ...state,
        open: true
      }
    case TOGGLE_PAYER_ALLOW_PAYMENTS:
      const actionTogglePayerAllowPayments = action as TogglePayerAllowPaymentsAction;
      return {
        ...state,
        payerDetails: {
          ...state.payerDetails,
          allowPayments: actionTogglePayerAllowPayments.allowPayments
        },
      }
    case CLOSE_HOMEOWNER_DETAILS:
      return {
        ...state,
        initialized: false,
        loading: false,
        open: false
      }
    default:
      return state
  }
}

const getHomeownerDetailsInitialState = (): HomeownerDetailsInterface => {
  return {
    payerDetails: {
      id: 0,
      name: "",
      emailAddress: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      postalCode: "",
      accountNumber: "",
      communityName: "",
      communityId: 0,
      country: "",
      allowPayments: false
    },
    initialized: false,
    loading: false,
    open: false,
    dataSource: {limit: 999, offset: 0, itemCount: 0, items: []},
    transactions: {limit:999, offset: 0, itemCount:0, items: []}
  }
}
