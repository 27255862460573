import {all, call, fork, put, takeLatest} from "redux-saga/effects";
import {
  FETCH_REPRESENTATIVE_PROFILE,
  UPDATE_REPRESENTATIVE_PROFILE
} from "../constants/representativeProfileConstants";
import {
  getRepresentative,
  updateRepresentative
} from "../api/RepresentativeAPI";
import {
  hideLoading,
  receiveRepresentativeProfile,
  showLoading, showPageUnavailableError,
  showRepresentativeProfileConfirmation, showRepresentativeProfileError
} from "../actions/representativeProfile";
import {
  FetchRepresentativeProfileAction,
  UpdateRepresentativeProfileAction
} from "../actions/representative.profile.action";
import RepresentativeInterface from "../interfaces/representative.interface";

/**
 * Saga to observe FETCH_REPRESENTATIVE_PROFILE
 */
export function* watchFetchRepresentativeProfile() {
  yield takeLatest(FETCH_REPRESENTATIVE_PROFILE, fetchRepresentativeProfileSaga);
}

/**
 * Resolve FETCH_INITIAL_DATA
 */
function* fetchRepresentativeProfileSaga(action: FetchRepresentativeProfileAction) {
  try {
    const representative: RepresentativeInterface  = yield call(getRepresentative);
    yield put(receiveRepresentativeProfile(representative))
  } catch (e) {
    console.warn('Error in fetchRepresentativeProfileSaga: ', e)
    switch (e.status) {
      case 401:
      case 403:
        yield put(showPageUnavailableError)
        break;
      default:
        yield put(showRepresentativeProfileError(e.data))
    }
  }
}


/**
 * Saga to observe UPDATE_REPRESENTATIVE_PROFILE
 */
export function* watchUpdateRepresentativeProfile() {
  yield takeLatest(UPDATE_REPRESENTATIVE_PROFILE, updateRepresentativeProfileSaga);
}

/**
 * Resolve UPDATE_REPRESENTATIVE_PROFILE
 */
function* updateRepresentativeProfileSaga(action: UpdateRepresentativeProfileAction) {
  try {
    yield put(showLoading)
    yield call(updateRepresentative, action.representative)
    yield put(showRepresentativeProfileConfirmation)
  } catch (e) {
    yield put(showRepresentativeProfileError(e.data))
    console.warn('Error in updateRepresentativeProfileSaga: ', e)
    switch (e.status) {
      case 401:
      case 403:
        yield put(showPageUnavailableError)
        break;
      default:
        yield put(showRepresentativeProfileError(e.data))
    }
  } finally {
    yield put(hideLoading)
  }
}


export function* representativeProfileSagas() {
  yield all([
    fork(watchFetchRepresentativeProfile),
    fork(watchUpdateRepresentativeProfile)
  ]);
}
