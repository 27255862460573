import {RECEIVE_GLOBAL_CONTROLS, RECEIVE_USER_COMPANY, RECEIVE_USER_INFO} from "../constants/userInfo";
import {UserInfoAction} from "../actions/user.info.action";
import {UserInterface} from "../interfaces/user.interface";

export const userInfoReducer = (store: UserInterface = {isFSEmployee : false}, action: UserInfoAction): UserInterface => {
  switch (action.type) {
    case RECEIVE_USER_INFO:
      return {
        ...store,
        ...action.userInfo,
        isOnboardUser: false,
        fullAccessProfile: false,
        isReportsUser: false,
      }
    case RECEIVE_USER_COMPANY:
      return {
        ...store,
        isOnboardUser: action.userInfo.isOnboardUser,
        fullAccessProfile: action.userInfo.fullAccessProfile,
        isReportsUser: action.userInfo.isReportsUser,
        globalReportsEnabled: action.userInfo.globalReportsEnabled,
        isFSEmployee: action.userInfo.isFSEmployee,
        userCompany: {
          ...store.userCompany,
          ...action.userCompany
        }
      }
    case RECEIVE_GLOBAL_CONTROLS:
      return {
        ...store,
        globalReportsEnabled: action.globalControls.reportsEnabled
      }
    default:
      return store
  
  }
}
