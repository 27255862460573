/**
 * FAQ Component
 */
import React from "react";
import {connect, ConnectedProps} from "react-redux";
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: "bold",
  },
}));

const FaqComponent =  React.memo((props: FaqComponentProps) => {
  const classes = useStyles();

  return(
    <div className="row justify-content-center pageHeader">
      <div className="col col-md-8 mt-5">
        <h1 className="text-center">Frequently Asked Questions</h1>
        <p className="text-center mt-4">Still have questions? Reach out to our dedicated FRONTSTEPS Payments support team!</p>
        <p className="text-center mt-4">Call us at <b className="no-break">(720) 773-7755</b> or email us at
          <br />
          <b className="no-break">fspay@frontsteps.com</b> for assistance!</p>
        <br/>
        <p className="text-center mt-4 mb-5">Hours of Operation: Monday - Friday, 8am MT to 5pm MT.</p>
      </div>
      <br />
      <div className="accordion col-12 col-md-10" id="accordion">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-content-0"
            id="panel-header-0"
          >
            <Typography className={classes.heading}>What do the payment status’ mean?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ul>
                <li><strong>Pending &ndash;</strong><span>&nbsp;A payment is&nbsp;</span>awaiting settlement<span>.</span>
                  <ul>
                    <li><span>It is typical for payments made with an ACH (eCheck) payment method to remain in a Pending status for 3-5 business days.</span></li>
                    <li><span>After 3-5 business days, the homeowner&rsquo;s bank will confirm a successful payment or failed payment.</span></li>
                  </ul>
                </li>
                <li><strong>Succeeded &ndash;</strong><span>&nbsp;A homeowner was charged, and the payment was successful.</span></li>
                <li><strong>Failed &ndash;</strong><span>&nbsp;A homeowner&rsquo;s attempt to pay failed. This can be due to a credit card decline, de-activated card, etc.</span></li>
                <li><strong>Returned &ndash;</strong><span>&nbsp;ACH payments that were attempted but failed. This can be due to Insufficient funds, a closed Bank Account, etc.</span>
                  <ul>
                    <li><span>If you are using FRONTSTEPS Caliber, these automatically payments are posted to the ledger, assuming they are successful. But, since they eventually failed, these specific payments need to be reversed from the homeowner&rsquo;s ledger.</span></li>
                  </ul>
                </li>
                <li><strong>Refunded &ndash;</strong><span>&nbsp;A homeowner&rsquo;s payment that was refunded through the FSPay Dashboard.</span></li>
                <li><strong>Refund Failed &ndash;</strong><span>&nbsp;There was an error when attempting to refund the homeowner through the FSPay Dashboard.</span></li>
                <li><strong>Disputed &ndash;</strong><span>&nbsp;A homeowner disputed their payment.</span>
                  <ul>
                    <li><span>Disputed payments are refunded to the homeowner and the funds reversed from the association&rsquo;s account.</span></li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-content-1"
            id="panel-header-1"
          >
            <Typography className={classes.heading}>How far back can I go to see my payments?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Right now, there is no limit to how far back you can go. Be aware that it may take some time to load as there may be a lot of data.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-content-2"
            id="panel-header-2"
          >
            <Typography className={classes.heading}>Can I see why a payment failed?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Yes! Click on the ‘Show Details’ action and the failure reason will be displayed. <br/>
              Note, some payment failures will have a generic code of (E9999) The payment transaction failed. In cases of potential fraud, details are not given on what specifically is causing the error to prevent further attempts. If more details are required on why a payment has failed, please reach out to the FS Pay support team and we will be happy to assist.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-content-3"
            id="panel-header-3"
          >
            <Typography className={classes.heading}>Can I initiate a refund for any Pending payments?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Unfortunately, we are not able to process a refund until a payment has succeeded.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-content-4"
            id="panel-header-4"
          >
            <Typography className={classes.heading}>Can I process a partial refund?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              At this time, only a <u><b>full</b></u> refund can be processed for a payment.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-content-5"
            id="panel-header-5"
          >
            <Typography className={classes.heading}>When processing a refund, will the homeowner get the convenience fee refunded?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Yes! The homeowner will also get the convenience fee refunded back to them.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-content-6"
            id="panel-header-6"
          >
            <Typography className={classes.heading}>Where will the money be refunded to?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              The funds will be refunded to the original payment method. For example, if a homeowner made a payment via ACH (eCheck), then the money will be refunded to the same ACH (eCheck) account used to make the payment.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-content-7"
            id="panel-header-7"
          >
            <Typography className={classes.heading}>Will I see all homeowners across a Management Company’s communities?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Yes! All homeowners across different communities within your Management Company will be shown.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-content-8"
            id="panel-header-8"
          >
            <Typography className={classes.heading}>I don’t remember initiating a refund for a homeowner’s payment. How do I see who initiated a refund for a payment?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              You can see the details around a refund by clicking ‘Show Details’ on a refunded payment. It will show: <br/>
              <ul>
                <li>Who Refunded the Payment (Email Address)</li>
                <li>When the Payment was Refunded</li>
                <li>The Reason the Payment was Refunded</li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-content-9"
            id="panel-header-9"
          >
            <Typography className={classes.heading}>Where can I see recurring payments that have been scheduled for a homeowner, if any?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              To determine if a homeowner has any recurring payments scheduled, find the homeowner (by using any of the filters available) and click ‘Show Details’.
              Under Scheduled Payments you will see:
              <br/>
              <ul>
                <li>If there are no recurring payments scheduled, then ‘No records found’ will be displayed.</li>
                <li>If there are recurring payments scheduled, then there will be information about each recurring payment with:</li>
                <li>
                  <ul>
                    <li>Frequency</li>
                    <li>
                      <ul>
                        <li>If applicable, the Day of the Month</li>
                      </ul>
                    </li>
                    <li>Amount</li>
                    <li>Start Date</li>
                    <li>Next Payment Date</li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-content-10"
            id="panel-header-10"
          >
            <Typography className={classes.heading}>Can I edit or delete a recurring payment for a homeowner?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              You are unable to edit a recurring payment, but can delete a recurring payment for the homeowner.
              <br/>
              To delete a recurring payment for a homeowner:
              <br/>
              <ul>
                <li>Click ‘Delete’ for the specific recurring payment.</li>
                <li>Click to ‘Yes’ when asked “Are you sure you want to delete the scheduled payment?”</li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-content-11"
            id="panel-header-11"
          >
            <Typography className={classes.heading}>Can I view and re-send a payment receipt to a homeowner?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p>Yes, you can view and re-send receipts to a homeowner for any Succeeded payment.</p>
              <br/>
              <p>To view and re-send a payment receipt to a homeowner:</p>
              <ul>
                <li><span>Find the specific payment in question.</span></li>
                <li><span>Click &lsquo;View Receipt&rsquo; under Actions.</span></li>
                <li><span>Edit the email, if necessary.</span></li>
                <li><span>Click &lsquo;Send Receipt&rsquo;.</span></li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-content-12"
            id="panel-header-12"
          >
            <Typography className={classes.heading}>What are the Reversals under the Payout Details?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p>Reversals are payments that have previously been paid to the association but have since been returned. Payments that are reversed are payments with the status’ of:</p>
              <br/>
              <ul>
                <li>RETURNED</li>
                <li>DISPUTED</li>
                <li>REFUNDED</li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
});

const connector = connect()

type FaqComponentProps = ConnectedProps<typeof connector>

export default connector(FaqComponent)
