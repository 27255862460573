import axios, {AxiosError} from "axios";
import {getTokenSilently, logout} from "../Auth";
import {config} from "../config";
import {UserLoginInterface} from "../interfaces/user.login.interface";

const AUTH_USER_REFRESH_ACCESS_URL = `${config.baseUrl}${config.suffixApi}${config.versionApi}/auth/refresh-access`;

interface HttpServiceInterface {
  get<T = any>(url: string, params?: URLSearchParams): Promise<T>
}

class HttpService implements HttpServiceInterface {
  
  get<T = any>(url: string, params?: URLSearchParams): Promise<T> {
    return new Promise<T>(async (res, rej) => {
      axios.get<T>(url, {
        headers: await HttpService.getHeaders(),
        params: params
      }).then((response) => {
        res(response.data)
      }).catch((error: AxiosError) => {
        rej(error.response)
      })
    })
  }
  
  download<T = any>(url: string, params?: URLSearchParams): Promise<T> {
    return new Promise<T>(async (res, rej) => {
      axios.get<T>(url, {
        headers: await HttpService.getHeaders(),
        params: params,
        responseType: "blob"
      }).then((response) => {
        res(response.data)
      }).catch((error: AxiosError) => {
        rej(error.response)
      })
    })
  }
  
  delete<T = any>(url: string, params?: URLSearchParams): Promise<T> {
    return new Promise<T>(async (res, rej) => {
      axios.delete<T>(url, {
        headers: await HttpService.getHeaders(),
        params: params,
      }).then((response) => {
        res(response.data)
      }).catch((error: AxiosError) => {
        rej(error.response)
      })
    })
  }
  
  post<T = any>(url: string, params?: any): Promise<T> {
    return new Promise<T>(async (res, rej) => {
      axios.post<T>(url, params,{
        headers: await HttpService.getHeaders()
      }).then((response) => {
        res(response.data)
      }).catch((error: AxiosError) => {
        rej(error.response)
      })
    })
  }
  
  put<T = any>(url: string, params?: any, queryParams?: URLSearchParams): Promise<T> {
    return new Promise<T>(async (res, rej) => {
      axios.put<T>(url, params,{
        headers: await HttpService.getHeaders(),
        params: queryParams
      }).then((response) => {
        res(response.data)
      }).catch((error: AxiosError) => {
        rej(error.response)
      })
    })
  }
  
  private static async getHeaders(): Promise<any> {
    const token = await HttpService.getToken();
    return {
      Accept: '*/*',
      Authorization: `Bearer ${token}`,
      'X-FRONTSTEPS-Payments-UserType':  sessionStorage.getItem('suiteManagerToken') ? 'SuiteManager' : 'Auth0',
      'X-FRONTSTEPS-Payments-Userdata': sessionStorage.getItem('userData') || '',
      'Content-Type': 'application/json'
    }
  }
  
  private static async useRefreshToken(refreshToken: string): Promise<void> {
    const res: UserLoginInterface = await axios.post<UserLoginInterface>(AUTH_USER_REFRESH_ACCESS_URL, {refreshToken})
      .then((response) => {
          return response.data;
        });
    sessionStorage.setItem('suiteManagerToken', res.accessToken);
    sessionStorage.setItem('suiteManagerRefresh', res.refreshToken);
  }
  
  private static async getToken(): Promise<string | undefined> {
    try {
      const suiteManagerToken = sessionStorage.getItem('suiteManagerToken')
      const suiteManagerRefresh = sessionStorage.getItem('suiteManagerRefresh')
      
      if (!!suiteManagerToken) {
        
        // non-expired access token is authenticated
        if (!this.tokenExpired(suiteManagerToken)) {
          return suiteManagerToken;
        } else {
          
          // if accessToken is expired, use refresh token
          if (!!suiteManagerRefresh) {
            try {
              await this.useRefreshToken(suiteManagerRefresh); // accessToken is reset when using refresh token
              const newAccessToken = sessionStorage.getItem('suiteManagerToken');
              if (!this.tokenExpired(newAccessToken)) {
                return newAccessToken || undefined;
              }
            } catch (err) {
              // failure to use refresh token is not authenticated
              logout({returnTo: config.redirectUri})
              return;
            }
          }
        }
        return suiteManagerToken;
      }
      
      // Auth0 token
      const token = await getTokenSilently();
      if (token) {
        return token
      } else {
        logout({returnTo: config.redirectUri})
      }
    } catch (e) {
      logout({returnTo: config.redirectUri})
    }
  }
  private static getTokenExpirationTime(token: string, bufferInSeconds = 30): number | null {
    if (!token) {
      return null;
    }
    
    const payload = token.split('.')[1];
    if (!payload) {
      return null;
    }
    
    try {
      const decodedPayload = JSON.parse(atob(payload));
      if (!decodedPayload.exp) {
        return null;
      }
      
      // JWT exp is in seconds, convert it to milliseconds
      // Subtracting bufferTime in seconds (default 30)
      return (decodedPayload.exp - bufferInSeconds) * 1000;
    } catch (err) {
      return null;
    }
  }
  private static tokenExpired(token: string | null): boolean {
    if (!token) {
      return true;
    }
    
    const tokenExpiration = this.getTokenExpirationTime(token);
    if (!tokenExpiration) {
      return true;
    }
    
    return new Date(tokenExpiration) < new Date()
  }
}

const httpService = new HttpService()

export default httpService

