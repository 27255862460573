import { all, fork } from 'redux-saga/effects';
import {paymentTransactionSagas} from './paymentTransactionsSaga';
import {userInfoSagas} from "./userInfoSaga";
import {errorHandlerSagas} from "./errorHandlerSaga";
import {homeownerSagas} from "./homeownersSaga";
import {homeownerDetailsSagas} from "./homeownerDetailsSaga";
import {payoutsSagas} from "./payoutSaga";
import {dashboardUsersSagas} from "./dashboardUserSaga";
import {lockboxFilesSagas} from "./lockboxfileSaga"
import {onboardingSagas} from "./onboardingSaga"
import {taxFormsSagas} from './taxFormsSaga'
import {representativeProfileSagas} from './representativeProfileSaga'
import {representativeUsersSagas} from "./representativeUserSaga";
import {reportsSagas} from "./reportsSaga";

export const rootSaga = function* root() {
  yield all([
    fork(paymentTransactionSagas),
    fork(userInfoSagas),
    fork(errorHandlerSagas),
    fork(homeownerSagas),
    fork(homeownerDetailsSagas),
    fork(payoutsSagas),
    fork(dashboardUsersSagas),
    fork(lockboxFilesSagas),
    fork(onboardingSagas),
    fork(taxFormsSagas),
    fork(representativeProfileSagas),
    fork(representativeUsersSagas),
    fork(reportsSagas)
  ]);
};
