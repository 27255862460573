import { FETCH_LOCKBOXFILES, FETCH_LOCKBOXFILES_INITIAL_DATA, HIDE_LOCKBOXFILE_LOADING, RECEIVE_LOCKBOXFILES, SHOW_LOCKBOXFILE_LOADING, UPDATE_LOCKBOX_FILTER } from "../constants/lockboxFiles";
import { DatatableResponseInterface } from "../interfaces/datatable.response.interface";
import { DatatableLockboxFileRequestInterface } from "../interfaces/lockboxfiles.datatable.request.interface";
import { LockboxFileFiltersInterface } from "../store";
import {
    FetchLockboxFileAction,
    ReceiveLockboxFilesAction, UpdateFilterAction
  } from "./lockboxfiles.action";

export const fetchInitialData = {
    type: FETCH_LOCKBOXFILES_INITIAL_DATA
}

export const receiveLockboxFiles = (payload: DatatableResponseInterface) : ReceiveLockboxFilesAction => {
    return {
        type: RECEIVE_LOCKBOXFILES,
        payload
    }
}

export const updateFilter = (payload: LockboxFileFiltersInterface) : UpdateFilterAction => {
    return {
      type: UPDATE_LOCKBOX_FILTER,
      payload
    }
  }

  export const fetchLockboxFiles = (request: DatatableLockboxFileRequestInterface) : FetchLockboxFileAction => {
    return {
      type: FETCH_LOCKBOXFILES,
      request: request
    }
  }

  export const showLoading = {
    type: SHOW_LOCKBOXFILE_LOADING
  }

  export const hideLoading = {
    type: HIDE_LOCKBOXFILE_LOADING
  }