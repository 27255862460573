import httpService from "./HttpService";
import {ScheduledReportInterface} from "../interfaces/scheduled.report.interface";
import {config} from "../config";
import {ScheduledReport} from "../models/scheduled.report.model";

const SCHEDULED_REPORT_URL = `${config.baseUrl}${config.suffixApi}${config.versionApi}/reporting/scheduledReports`;
const SCHEDULED_REPORT_BY_ID_URL = (reportId: number) =>`${config.baseUrl}${config.suffixApi}${config.versionApi}/reporting/scheduledReports/${reportId}`;

export const getScheduledReports = ():Promise<ScheduledReportInterface[]> => {
  return httpService.get<ScheduledReportInterface[]>(SCHEDULED_REPORT_URL);
}

export const deleteScheduledReport = (id: number): Promise<void> => {
  return httpService.delete<void>(SCHEDULED_REPORT_BY_ID_URL(id))
}

export const createScheduledReport = (report: ScheduledReport): Promise<ScheduledReportInterface> => {
  return httpService.post(SCHEDULED_REPORT_URL, report);
}

export const getScheduledReport = (id: number): Promise<ScheduledReportInterface> => {
  return httpService.get<ScheduledReportInterface>(SCHEDULED_REPORT_BY_ID_URL(id));
}
