import React from "react";
import {submitOnboardingApplication} from "../../../api/OnboardingAPI";
import history from "../../../utils/history";

const OnboardingTable = () => {
  
  React.useState(() => {
    const id = localStorage.getItem('OnboardApplicationId');
    submitOnboardingApplication(Number(id)).then(() => {
      history.push('Onboarding')
    });
  });
  
  return <div>
    Loading
  </div>
}

export default OnboardingTable;
