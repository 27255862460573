import {all, call, fork, put, takeLatest} from "redux-saga/effects";
import {
  CLOSE_REPRESENTATIVE_USER_MODAL,
  FETCH_REPRESENTATIVE_USER_INITIAL_DATA,
  FETCH_REPRESENTATIVE_USERS
} from "../constants/representativeUserConstants";
import {
  FetchRepresentativeUsersAction
} from "../actions/representative.user.action";
import {
  hideRepresentativeUserLoading,
  receiveRepresentativeUserInitialData,
  receiveRepresentativeUsers,
  showRepresentativeUserLoading
} from "../actions/representativeUser";
import {catchException} from "./errorHandlerSaga";
import RepresentativeInterface from "../interfaces/representative.interface";
import {getRepresentatives} from "../api/RepresentativeAPI";
import {fetchManagementCompanies} from "../api/Auth0API";

/**
 * Saga to observe FETCH_REPRESENTATIVE_USERS action
 */
export function* watchRequestRepresentativeUsersSaga() {
  yield takeLatest(FETCH_REPRESENTATIVE_USERS, requestRepresentativeUsersSaga)
}

/**
 * Resolver for FETCH_REPRESENTATIVE_USERS
 * @param action
 */
function* requestRepresentativeUsersSaga(action: FetchRepresentativeUsersAction) {
  try {
    yield put(showRepresentativeUserLoading)
    const users: RepresentativeInterface[] = yield call(getRepresentatives, action.managementCompanyId)
    yield put(receiveRepresentativeUsers(users))
  } catch (e) {
    console.warn('Error in requestRepresentativeUsersSaga: ', e)
    yield catchException(e)
  } finally {
    yield put(hideRepresentativeUserLoading)
  }
  
}

/**
 * Saga to observe FETCH_REPRESENTATIVE_USER_INITIAL_DATA
 */
export function* watchFetchInitialDataSaga() {
  yield takeLatest(FETCH_REPRESENTATIVE_USER_INITIAL_DATA, fetchInitialDataSaga);
}

/**
 * Resolve FETCH_REPRESENTATIVE_USER_INITIAL_DATA
 */
function* fetchInitialDataSaga() {
  try {
    const managementCompanies = yield call(fetchManagementCompanies);
    yield put(receiveRepresentativeUserInitialData(managementCompanies))
  } catch (e) {
    console.warn('Error in fetchInitialDataSaga: ', e)
    yield catchException(e)
  }
}

export function* watchCloseRepresentativeModal() {
  yield takeLatest(CLOSE_REPRESENTATIVE_USER_MODAL, closeRepresentativeModalSaga);
}

function* closeRepresentativeModalSaga() {
  try {
    yield put(receiveRepresentativeUsers([]))
  } catch (e) {
    console.warn('Error in closeRepresentativeModalSaga: ', e)
    yield catchException(e)
  }
}

export function* representativeUsersSagas() {
  yield all([
    fork(watchFetchInitialDataSaga),
    fork(watchRequestRepresentativeUsersSaga),
    fork(watchCloseRepresentativeModal)
  ]);
}
