import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import { fetchLockboxFiles, hideLoading, receiveLockboxFiles, showLoading } from '../actions/lockboxFiles';
import { FetchLockboxFileAction, UpdateFilterAction } from '../actions/lockboxfiles.action';
import { getLockboxFiles } from '../api/LockboxFilesAPI';
import { FETCH_LOCKBOXFILES, FETCH_LOCKBOXFILES_INITIAL_DATA, UPDATE_LOCKBOX_FILTER } from '../constants/lockboxFiles';
import { DatatableResponseInterface } from '../interfaces/datatable.response.interface';
import { DatatableLockboxFileRequestInterface } from '../interfaces/lockboxfiles.datatable.request.interface';
import { convertToLockboxRequest, getInitialRequestForLockboxFiles } from '../utils/helpers';
import {catchException} from "./errorHandlerSaga";

/**
 * Saga to observe FETCH_LOCKBOXFILES_INITIAL_DATA
 */
 export function* watchFetchInitialDateSaga() {
    yield takeLatest(FETCH_LOCKBOXFILES_INITIAL_DATA, fetchInitialDataSaga);
  }

/**
 * Resolve FETCH_LOCKBOXFILES_INITIAL_DATA
 */
function* fetchInitialDataSaga() {
    try {
      const request: DatatableLockboxFileRequestInterface = getInitialRequestForLockboxFiles()
      const response = yield call(getLockboxFiles, request)
      yield put(receiveLockboxFiles(response))
    } catch (e) {
      console.warn('Error in fetchInitialDataSaga: ', e)
      yield catchException(e)
    }
  }

/**
 * Saga to observe UPDATE_LOCKBOX_FILTER action
 */
export function* watchUpdateFiltersSaga() {
  yield takeLatest(UPDATE_LOCKBOX_FILTER, updateFilterSaga);
}

/**
 * Resolver for UPDATE_LOCKBOX_FILTER
 * @param action
 */
 function* updateFilterSaga(action: UpdateFilterAction) {
  const request: DatatableLockboxFileRequestInterface = convertToLockboxRequest(action.payload);
  yield put(fetchLockboxFiles(request));
}

/**
 * Saga to observe FETCH_LOCKBOXFILES action
 */
 export function* watchRequestPayoutsSaga() {
  yield takeLatest(FETCH_LOCKBOXFILES, requestPayoutsSaga)
}

/**
 * Resolver for FETCH_LOCKBOXFILES
 * @param action
 */
function* requestPayoutsSaga(action: FetchLockboxFileAction) {
  try {
    yield put(showLoading)
    const response: DatatableResponseInterface = yield call(getLockboxFiles, action.request)
    yield put(receiveLockboxFiles(response))
  } catch (e) {
    console.warn('Error in requestLockboxSaga: ', e)
    yield catchException(e)
  } finally {
    yield put(hideLoading)
  }
  
}


  export function* lockboxFilesSagas() {
    yield all([
      fork(watchFetchInitialDateSaga),
      fork(watchUpdateFiltersSaga),
      fork(watchRequestPayoutsSaga)
    ]);
  }
  