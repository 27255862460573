import {takeLatest, call, put, fork, all} from "redux-saga/effects";
import {FETCH_GLOBAL_CONTROLS, FETCH_USER_COMPANY} from "../constants/userInfo";
import {receiveGlobalControls, receiveUserCompany} from "../actions/userInfo";
import {fetchUserCompany} from "../api/Auth0API";
import {FetchUserCompanyAction} from "../actions/user.info.action";
import {catchException} from "./errorHandlerSaga";
import {UserCompanyInterface} from "../interfaces/user.company.interface";
import {getGlobalControls} from "../api/AppControlsAPI";
import GlobalControlsInterface from "../interfaces/global.controls.interface";

function* fetchUserInfoSaga(action: FetchUserCompanyAction) {
  try {
    const userCompany: UserCompanyInterface = yield call(fetchUserCompany, action.companyId)
    yield put(receiveUserCompany(userCompany, action.userInfo))
  } catch (e) {
    console.warn('Error in fetchResidentsSaga: ', e)
    yield catchException(e)
  }
}

export function* watchFetchUserInfo() {
  yield takeLatest(FETCH_USER_COMPANY, fetchUserInfoSaga);
}

function* fetchGlobalControlsSaga() {
  try {
    const globalControls: GlobalControlsInterface = yield call(getGlobalControls)
    yield put(receiveGlobalControls(globalControls))
  } catch (e) {
    console.warn('Error in fetchResidentsSaga: ', e)
    yield catchException(e)
  }
}

export function* watchFetchGlobalControls() {
  yield takeLatest(FETCH_GLOBAL_CONTROLS, fetchGlobalControlsSaga);
}

export function* userInfoSagas() {
  yield all([
    fork(watchFetchUserInfo),
    fork(watchFetchGlobalControls)
  ]);
}
