/**
 * Homeowners constants
 */
export const UPDATE_HOMEOWNERS_FILTER = 'UPDATE_HOMEOWNERS_FILTER'
export const FETCH_HOMEOWNERS = 'FETCH_HOMEOWNERS'
export const RECEIVE_HOMEOWNERS = 'RECEIVE_HOMEOWNERS'
export const RECEIVE_HOMEOWNERS_INITIAL_DATA = 'RECEIVE_HOMEOWNERS_INITIAL_DATA'
export const FETCH_HOMEOWNERS_INITIAL_DATA = 'FETCH_HOMEOWNERS_INITIAL_DATA'
export const SELECT_HOMEOWNER = 'SELECT_HOMEOWNER'
export const SHOW_HOMEOWNERS_LOADING = 'SHOW_HOMEOWNERS_LOADING'
export const HIDE_HOMEOWNERS_LOADING = 'HIDE_HOMEOWNERS_LOADING'

