import React, { useState, useEffect, useContext } from 'react'
import createAuth0Client, {
    Auth0ClientOptions,
    getIdTokenClaimsOptions, GetTokenSilentlyOptions, GetTokenWithPopupOptions,
    IdToken, LogoutOptions,
    PopupLoginOptions, RedirectLoginOptions,
    RedirectLoginResult
} from '@auth0/auth0-spa-js'
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client'
import {UserProductInterface} from './interfaces/user.product.interface';
import * as QueryString from "query-string";
import {getUserLogin} from "./api/Auth0API";

export interface Auth0RedirectState {
    targetUrl?: string
}

export interface Auth0User  {
    sub: string,
    name: string,
    email: string
}

interface Auth0Context {
    user?: Auth0User
    isAuthenticated: boolean
    loading: boolean
    isPopupOpen: boolean
    userProduct?: UserProductInterface,
    loginWithPopup(o?: PopupLoginOptions): Promise<void>
    handleRedirectCallback(): Promise<RedirectLoginResult>
    getIdTokenClaims(o?: getIdTokenClaimsOptions): Promise<IdToken>
    loginWithRedirect(o?: RedirectLoginOptions): Promise<void>
    getTokenSilently(o?: GetTokenSilentlyOptions): Promise<string | undefined>
    getTokenWithPopup(o?: GetTokenWithPopupOptions): Promise<string | undefined>
    logout(o?: LogoutOptions): void
}
interface Auth0ProviderOptions {
    children: React.ReactElement
    onRedirectCallback(result: RedirectLoginResult): void
}

let _initOptions: Auth0ClientOptions, _client: Auth0Client
const getAuth0Client = ():Promise<Auth0Client> => {
    return new Promise<Auth0Client>(async (res, rej) => {
        try {
            _client = await createAuth0Client(_initOptions)
            res(_client)
        } catch (e) {
            rej(new Error('getAuth0Client Error'))
        }
    })
}
// getToken outside hook
export const getTokenSilently = async (options?: GetTokenSilentlyOptions) => {
    if(!_client) {
        _client = await getAuth0Client()
    }
    return await _client.getTokenSilently(options)
}
// destroy session outside hook
export const logout = (options?: LogoutOptions) => {
    if(!!sessionStorage.getItem('suiteManagerToken')) {
        sessionStorage.removeItem('suiteManagerToken')
        sessionStorage.removeItem('suiteManagerRefresh')
        window.location.href = '/login';
    }
    if(!_client){
        _client!.logout(options)
    }
}

export const Auth0Context = React.createContext<Auth0Context | null>(null)
export const useAuth0 = () => useContext(Auth0Context)!
export const Auth0Provider = ({
                                  children,
                                  onRedirectCallback,
                                  ...initOptions
                              }: Auth0ProviderOptions & Auth0ClientOptions) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [loading, setLoading] = useState(true)
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const [user, setUser] = useState<Auth0User>()
    const [auth0Client, setAuth0Client] = useState<Auth0Client>()
    const [userProduct, setUserProduct] = useState<UserProductInterface>()

    useEffect(() => {
        
        const initSuiteManagerAuth = async () => {
            try {
                const userLogin = await getUserLogin();
                if (!!userLogin) {
                    setIsAuthenticated(true)
                    setUserProduct(userLogin)
                    sessionStorage.setItem('suiteManagerRefresh', userLogin.refreshToken);
                }
                
                setLoading(false)
            } catch (e) {
                sessionStorage.removeItem('suiteManagerToken')
                sessionStorage.removeItem('suiteManagerRefresh')
            }
        };
        const initAuth0 = async () => {
            _initOptions = initOptions
            const auth0FromHook = await getAuth0Client()
            setAuth0Client(auth0FromHook)

            if (window.location.search.includes('code=') &&
                window.location.search.includes("state=")) {
                let appState: RedirectLoginResult | undefined = {}
                try {
                    ({ appState } = await auth0FromHook.handleRedirectCallback())
                }
                finally {
                    if (appState)
                        onRedirectCallback(appState)
                }
            }

            const authed = await auth0FromHook.isAuthenticated()

            if (authed) {
                const userProfile: Auth0User | undefined = await auth0FromHook.getUser()
                setIsAuthenticated(true)
                setUser(userProfile)
            }

            setLoading(false)
        };
        
        if (window.location.search.includes('accessToken=')) {
            const queryString = QueryString.parse(location.search);
            sessionStorage.setItem('suiteManagerToken', queryString?.accessToken?.toString() || '');
            initSuiteManagerAuth();
        } else if (!!sessionStorage.getItem('suiteManagerToken')) {
            initSuiteManagerAuth();
        } else {
            initAuth0();
        }
    }, [])

    const loginWithPopup = async (options?: PopupLoginOptions) => {
        setIsPopupOpen(true)

        try {
            await auth0Client!.loginWithPopup(options)
        } catch (error) {
            console.error(error)
        } finally {
            setIsPopupOpen(false)
        }

        const userProfile: Auth0User | undefined = await auth0Client!.getUser()
        setUser(userProfile)
        setIsAuthenticated(true)
    }

    const handleRedirectCallback = async () => {
        setLoading(true)

        const result = await auth0Client!.handleRedirectCallback()
        const userProfile:Auth0User | undefined = await auth0Client!.getUser()

        setLoading(false)
        setIsAuthenticated(true)
        setUser(userProfile)

        return result
    }

    const loginWithRedirect = (options?: RedirectLoginOptions) =>
    auth0Client!.loginWithRedirect(options)

    const getTokenSilently = (options?: GetTokenSilentlyOptions) =>
    auth0Client!.getTokenSilently(options)

    const logout = (options?: LogoutOptions) =>
    auth0Client!.logout(options)

    const getIdTokenClaims = async (options?: getIdTokenClaimsOptions) : Promise<IdToken> => {
        const token = await auth0Client!.getIdTokenClaims(options);
        if (token) {
            return token;
        }
        throw "Token invalid";
    }
    

    const getTokenWithPopup = (options?: GetTokenWithPopupOptions) =>
    auth0Client!.getTokenWithPopup(options)

    return (
        <Auth0Context.Provider
            value={{
                user,
                isAuthenticated,
                loading,
                isPopupOpen,
                userProduct,
                loginWithPopup,
                loginWithRedirect,
                logout,
                getTokenSilently,
                handleRedirectCallback,
                getIdTokenClaims,
                getTokenWithPopup
            }}
        >
            {children}
        </Auth0Context.Provider>
    );
};
