import {NotificationHandlerInterface} from "../store";
import {NotificationHandlerAction} from "../actions/notification.handler.action";
import {CLOSE_NOTIFICATION, NOTIFY, SHOW_MODAL} from "../constants/notificationHandler";

export const notificationHandlerReducer = (store: NotificationHandlerInterface[] = [], action : NotificationHandlerAction): NotificationHandlerInterface[] => {
  switch (action.type) {
    case NOTIFY:
      return store.concat(action.notification)
    case CLOSE_NOTIFICATION:
      return store.filter((notification) => {
        return notification.notificationID !== action.notificationID
      })
    case SHOW_MODAL:
      return [action.notification]
    default:
      return store
  }
}
