/**
 * RepresentativeProfile constants
 */
export const FETCH_REPRESENTATIVE_PROFILE = 'FETCH_REPRESENTATIVE_PROFILE';
export const RECEIVE_REPRESENTATIVE_PROFILE = 'RECEIVE_REPRESENTATIVE_PROFILE';
export const UPDATE_REPRESENTATIVE_PROFILE = 'UPDATE_REPRESENTATIVE_PROFILE';
export const SHOW_REPRESENTATIVE_PROFILE_LOADING = 'SHOW_REPRESENTATIVE_PROFILE_LOADING';
export const HIDE_REPRESENTATIVE_PROFILE_LOADING = 'HIDE_REPRESENTATIVE_PROFILE_LOADING';
export const SHOW_REPRESENTATIVE_PROFILE_CONFIRMATION = 'SHOW_REPRESENTATIVE_PROFILE_CONFIRMATION';
export const SHOW_REPRESENTATIVE_PROFILE_ERROR = 'SHOW_REPRESENTATIVE_PROFILE_ERROR';
export const SHOW_PAGE_UNAVAILABLE_ERROR = 'SHOW_PAGE_UNAVAILABLE_ERROR';
