import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { ApplicationState } from './';
import createSagaMiddleware from 'redux-saga';
import createReducer from './reducers';
import logger from "../utils/logger";
import { rootSaga } from '../sagas';

export default function configureStore(history: History, initialState?: ApplicationState) {
    const reduxSagaMonitorOptions = {};
    const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
    const middleware = [
        routerMiddleware(history),
        sagaMiddleware,
        logger
    ];

    const enhancers = [];
    const windowIfDefined = typeof window === 'undefined' ? null : window as any;
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

    const store = createStore(
        createReducer(),
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
    
    sagaMiddleware.run(rootSaga);
    
    if (process.env.NODE_ENV === 'development' && module.hot) {
        module.hot.accept('./reducers', () => {
            const newRootReducer = require('./reducers').default
            store.replaceReducer(newRootReducer)
        })
    }
    return store;
}

