import {
  FETCH_GLOBAL_CONTROLS,
  FETCH_USER_COMPANY, RECEIVE_GLOBAL_CONTROLS,
  RECEIVE_USER_COMPANY,
  RECEIVE_USER_INFO
} from "../constants/userInfo";
import {
  FetchGlobalControlsAction,
  FetchUserCompanyAction, ReceiveGlobalControlsAction,
  ReceiveUserCompanyAction,
  ReceiveUserInfoAction
} from "./user.info.action";
import {UserInterface} from "../interfaces/user.interface";
import {UserCompanyInterface} from "../interfaces/user.company.interface";
import GlobalControlsInterface from "../interfaces/global.controls.interface";

export const receiveUserInfo = (userInfo: UserInterface) :ReceiveUserInfoAction => ({
  type: RECEIVE_USER_INFO,
  userInfo
})

export const receiveUserCompany = (userCompany: UserCompanyInterface, userInfo: UserInterface) :ReceiveUserCompanyAction => ({
  type: RECEIVE_USER_COMPANY,
  userCompany,
  userInfo,
})

export const fetchUserCompany = (companyId: string, userInfo: UserInterface) :FetchUserCompanyAction => ({
  type: FETCH_USER_COMPANY,
  companyId,
  userInfo,
})

export const fetchGlobalControls : FetchGlobalControlsAction = {
  type: FETCH_GLOBAL_CONTROLS
}

export const receiveGlobalControls = (globalControls: GlobalControlsInterface) :ReceiveGlobalControlsAction => ({
  type: RECEIVE_GLOBAL_CONTROLS,
  globalControls,
})
