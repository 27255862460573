/**
 * Payment Transactions constants
 */
export const UPDATE_FILTER = 'UPDATE_FILTER'
export const FETCH_PAYMENT_TRANSACTIONS = 'FETCH_PAYMENT_TRANSACTIONS'
export const RECEIVE_PAYMENT_TRANSACTIONS = 'RECEIVE_PAYMENT_TRANSACTIONS'
export const RECEIVE_INITIAL_DATA = 'RECEIVE_INITIAL_DATA'
export const FETCH_RESIDENTS = 'FETCH_RESIDENTS'
export const RECEIVE_RESIDENTS = 'RECEIVE_RESIDENTS'
export const FETCH_INITIAL_DATA = 'FETCH_INITIAL_DATA'
export const SHOW_LOADING = 'SHOW_LOADING'
export const HIDE_LOADING = 'HIDE_LOADING'
export const INITIALIZED = 'INITIALIZED'
export const SELECT_PAYMENT = 'SELECT_PAYMENT'
export const OPEN_REFUND_PAYMENT_MODAL = 'OPEN_REFUND_PAYMENT_MODAL'
export const CLOSE_REFUND_PAYMENT_MODAL = 'CLOSE_REFUND_PAYMENT_MODAL'
export const REFUND_PAYMENT = 'REFUND_PAYMENT'
export const OPEN_SEND_RECEIPT_MODAL = 'OPEN_SEND_RECEIPT_MODAL'
export const CLOSE_SEND_RECEIPT_MODAL = 'CLOSE_SEND_RECEIPT_MODAL'
export const SEND_RECEIPT = 'SEND_RECEIPT'

