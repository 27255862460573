/**
 * Onboarding constants
 */
export const UPDATE_ONBOARDING_FILTER = 'UPDATE_ONBOARDING_FILTER'
export const FETCH_ONBOARDING = 'FETCH_ONBOARDING'
export const RECEIVE_ONBOARDING = 'RECEIVE_ONBOARDING'
export const RECEIVE_ONBOARDING_INITIAL_DATA = 'RECEIVE_ONBOARDING_INITIAL_DATA'
export const FETCH_ONBOARDING_INITIAL_DATA = 'FETCH_ONBOARDING_INITIAL_DATA'
export const SELECT_ONBOARDING = 'SELECT_ONBOARDING'
export const SHOW_ONBOARDING_LOADING = 'SHOW_ONBOARDING_LOADING'
export const HIDE_ONBOARDING_LOADING = 'HIDE_ONBOARDING_LOADING'
