import {
  CLOSE_PAYOUT_DETAILS,
  HIDE_PAYOUT_LOADING,
  OPEN_PAYOUT_DETAILS,
  PAYOUT_INITIALIZED,
  RECEIVE_PAYOUT_INITIAL_DATA,
  RECEIVE_PAYOUTS,
  SHOW_PAYOUT_LOADING,
  UPDATE_PAYOUT_FILTER
} from "../constants/payouts"
import {PayoutsInterface} from "../store";
import moment from "moment";
import {config} from "../config";
import {OpenPayoutDetailsAction, PayoutsTypes} from "../actions/payouts.action";

export const payoutsReducer = (state : PayoutsInterface = getPayoutsInitialState(), action : PayoutsTypes): any => {
  switch (action.type) {
    case UPDATE_PAYOUT_FILTER:
      return {
        ...state,
        filters: {
          ...action.payload
        }
      }
    case RECEIVE_PAYOUTS:
      return {
        ...state,
        dataSource: {
          ...state.dataSource,
          ...action.payload
        }
      }
    case RECEIVE_PAYOUT_INITIAL_DATA:
      return {
        ...state,
        initialized: true,
        catalog: {
          communities: action.communities
        }
      }
    case SHOW_PAYOUT_LOADING:
      return {
        ...state,
        loading: true
      }
    case HIDE_PAYOUT_LOADING:
      return {
        ...state,
        loading: false
      }
    case PAYOUT_INITIALIZED:
      return {
        ...state,
        initialized: true
      }
    case OPEN_PAYOUT_DETAILS:
      const payload = action as OpenPayoutDetailsAction;
      return {
        ...state,
        openDetails: true,
        payoutSelectedId: payload.payoutId
      }
    case CLOSE_PAYOUT_DETAILS:
      return {
        ...state,
        openDetails: false,
      }
    default:
      return state
  }
}

const getPayoutsInitialState = (): PayoutsInterface => {
  return {
    filters: {
      selectedStartDate: moment().startOf('month').toDate(),
      selectedEndDate: moment().endOf('day').toDate(),
      pageNumber: 1,
      pageSize: config.paymentTransactionsPageSize
    },
    initialized: false,
    loading: false,
    catalog: {},
    dataSource: {limit: config.paymentTransactionsPageSize, offset: 0, itemCount: 0, items: []},
    openDetails: false
  }
}
