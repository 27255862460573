import {HANDLE_ERROR} from "../constants/notificationHandler";
import {HandleErrorAction} from "../actions/notification.handler.action";
import {handleErrorAction, sendNotification} from "../actions/notificationHandler";
import {fork, put, takeLatest} from "redux-saga/effects";
import {getNotificationFormat} from "../utils/helpers";
import {appInsights} from "../AppInsights";
import {SeverityLevel} from '@microsoft/applicationinsights-web';

export const genericErrorMessage = "Something went wrong. Try again later!"

/**
 * Handle errors
 */
function* handleError(action: HandleErrorAction) {
  if(action.code === 401 || action.code === 403) {
    // Setting customer message for Unauthorized or Forbidden response
    appInsights.trackException({ exception: new Error('Your session has expired or you are unauthorized to request this resource. Please log in or try again later!'), severityLevel: SeverityLevel.Information });
    yield put(
      sendNotification(getNotificationFormat(
        'info',
        'Your session has expired or you are unauthorized to request this resource. Please log in or try again later!',
        'Unauthorized request',
        false,
        action.code
      ))
    )
  } else {
    appInsights.trackException({ exception: new Error(`${action.hintMessage} ${action.message}`), severityLevel: SeverityLevel.Error });
    yield put(sendNotification(getNotificationFormat(
      'error',
      action.hintMessage,
      action.message
    )))
  }
}

function* watchHandleError() {
  yield takeLatest(HANDLE_ERROR, handleError)
}

export function* catchException(e: any) {
  if(e.status && e.statusText) {
    yield put(handleErrorAction(e.status, e.statusText, genericErrorMessage))
  } else {
    yield put(handleErrorAction(0, e.message, genericErrorMessage))
  }
}

export function* errorHandlerSagas() {
  yield fork(watchHandleError);
}
