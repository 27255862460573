import {AnyAction, Middleware, Dispatch} from "redux";

const logger: Middleware<{}, any, Dispatch<AnyAction>> = (store: any) => (next: any) => (action: any) => {
  console.group(action.type)
  console.log('The action: ', action)
  const result = next(action)
  console.log('The new state: ', store.getState())
  console.groupEnd()
  return result
}

export default logger