import {
  FETCH_REPORTS_INITIAL_DATA,
  HIDE_REPORTS_LOADING,
  RECEIVE_REPORTS_INITIAL_DATA,
  SHOW_REPORTS_LOADING,
  FETCH_REPORTS_RESIDENTS,
  RECEIVE_REPORTS_RESIDENTS, UPDATE_REPORTS_FILTER
} from "../constants/reportsConstants";
import {
  FetchReportsInitialDataAction,
  ReceiveReportsInitialDataAction,
  FetchReportsResidentsAction,
  ReceiveReportsResidentsAction,
  UpdateReportsFilterAction
} from "./reports.action";
import {CommunityInterface} from "../interfaces/community.interface";
import {ResidentInterface} from "../interfaces/resident.interface";
import {FiltersReportsInterface} from "../store";

export const fetchReportsInitialData: FetchReportsInitialDataAction = {
  type: FETCH_REPORTS_INITIAL_DATA
}

export const receiveReportsInitialData = (communities: CommunityInterface[]): ReceiveReportsInitialDataAction => {
  return {
    type: RECEIVE_REPORTS_INITIAL_DATA,
    communities
  }
}

export const fetchReportsResidents = (communityID: number) : FetchReportsResidentsAction => {
  return {
    type: FETCH_REPORTS_RESIDENTS,
    communityID
  }
}

export const receiveReportsResidents = (residents: ResidentInterface[]) : ReceiveReportsResidentsAction => {
  return {
    type: RECEIVE_REPORTS_RESIDENTS,
    residents
  }
}

export const updateReportsFilters = (payload: FiltersReportsInterface): UpdateReportsFilterAction => {
  return {
    type: UPDATE_REPORTS_FILTER,
    payload
  }
}

export const showReportsLoading = {
  type: SHOW_REPORTS_LOADING
}

export const hideReportsLoading = {
  type: HIDE_REPORTS_LOADING
}

