import PaymentMethodInterface from "../interfaces/payment.method.interface";
import ACHInterface from "../interfaces/ach.interface";
import CardInterface from "../interfaces/card.interface";
import {PaymentTypeEnum} from "../interfaces/payment.type.enum";

export default class PaymentMethod implements PaymentMethodInterface{
  ach: ACHInterface;
  card: CardInterface;
  discriminator: "CreditCard" | "DebitCard" | "Ach" | null;
  paymentType: PaymentTypeEnum;

  constructor({ach, card, discriminator}: PaymentMethodInterface) {
    this.ach = ach
    this.card = card
    this.discriminator = discriminator
    switch (this.discriminator) {
      case "Ach":
        this.paymentType = PaymentTypeEnum.ACH
        break
      case "CreditCard":
        this.paymentType = PaymentTypeEnum.CREDIT
        break
      case "DebitCard":
        this.paymentType = PaymentTypeEnum.DEBIT
        break
      default:
        this.paymentType = PaymentTypeEnum.CREDIT
    }
  }

  isACHPayment(): boolean {
    return this.discriminator === "Ach"
  }

  isCardPayment(): boolean {
    return this.discriminator === "CreditCard" || this.discriminator === "DebitCard"
  }

  getId(): number | undefined {
    if (this.isCardPayment() && this.card) {
      return this.card.id
    }

    if (this.isACHPayment() && this.ach) {
      return  this.ach.id
    }

    return -1
  }

}
