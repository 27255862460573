
import {
  FETCH_REPRESENTATIVE_PROFILE,
  HIDE_REPRESENTATIVE_PROFILE_LOADING,
  RECEIVE_REPRESENTATIVE_PROFILE,
  SHOW_REPRESENTATIVE_PROFILE_LOADING,
  SHOW_REPRESENTATIVE_PROFILE_CONFIRMATION,
  SHOW_REPRESENTATIVE_PROFILE_ERROR, UPDATE_REPRESENTATIVE_PROFILE, SHOW_PAGE_UNAVAILABLE_ERROR
} from "../constants/representativeProfileConstants";
import {
  FetchRepresentativeProfileAction,
  ReceiveRepresentativeProfileAction,
  ShowPageUnavailableErrorAction,
  ShowRepresentativeProfileErrorAction,
  UpdateRepresentativeProfileAction
} from "./representative.profile.action";
import RepresentativeInterface from "../interfaces/representative.interface";

export const fetchRepresentativeProfile = () : FetchRepresentativeProfileAction => {
  return {
    type: FETCH_REPRESENTATIVE_PROFILE
  }
}

export const receiveRepresentativeProfile = (representative: RepresentativeInterface) : ReceiveRepresentativeProfileAction => {
  return {
    type: RECEIVE_REPRESENTATIVE_PROFILE,
    representative
  }
}

export const updateRepresentativeProfile = (representative: RepresentativeInterface) : UpdateRepresentativeProfileAction => {
  return {
    type: UPDATE_REPRESENTATIVE_PROFILE,
    representative
  }
}

export const showLoading = {
  type: SHOW_REPRESENTATIVE_PROFILE_LOADING
}

export const hideLoading = {
  type: HIDE_REPRESENTATIVE_PROFILE_LOADING
}

export const showRepresentativeProfileConfirmation =  {
  type: SHOW_REPRESENTATIVE_PROFILE_CONFIRMATION
}

export const showPageUnavailableError =  {
  type: SHOW_PAGE_UNAVAILABLE_ERROR
}

export const showRepresentativeProfileError = (errorMessage?: string) : ShowRepresentativeProfileErrorAction => {
  return {
    type: SHOW_REPRESENTATIVE_PROFILE_ERROR,
    errorMessage
  }
}
