import {
  CloseRepresentativeUserModalAction,
  FetchRepresentativeUserInitialDataAction,
  FetchRepresentativeUsersAction,
  HideRepresentativeUserLoadingAction,
  OpenRepresentativeUserModalAction,
  ReceiveRepresentativeUserInitialDataAction,
  ReceiveRepresentativeUsersAction,
  ShowRepresentativeUserLoadingAction,
} from "./representative.user.action";
import {
  CLOSE_REPRESENTATIVE_USER_MODAL,
  FETCH_REPRESENTATIVE_USER_INITIAL_DATA,
  FETCH_REPRESENTATIVE_USERS,
  HIDE_REPRESENTATIVE_USER_LOADING,
  OPEN_REPRESENTATIVE_USER_MODAL,
  RECEIVE_REPRESENTATIVE_USER_INITIAL_DATA,
  RECEIVE_REPRESENTATIVE_USERS,
  SHOW_REPRESENTATIVE_USER_LOADING
} from "../constants/representativeUserConstants";
import {UserCompanyInterface} from "../interfaces/user.company.interface";
import RepresentativeInterface from "../interfaces/representative.interface";

export const fetchRepresentativeUsers = (managementCompanyId: number) : FetchRepresentativeUsersAction => ({
  type: FETCH_REPRESENTATIVE_USERS,
  managementCompanyId
})

export const receiveRepresentativeUsers = (users: RepresentativeInterface[]) : ReceiveRepresentativeUsersAction => ({
  type: RECEIVE_REPRESENTATIVE_USERS,
  users
})

export const fetchRepresentativeUserInitialData : FetchRepresentativeUserInitialDataAction = {
  type: FETCH_REPRESENTATIVE_USER_INITIAL_DATA
}

export const receiveRepresentativeUserInitialData = (managementCompanies: UserCompanyInterface[]) : ReceiveRepresentativeUserInitialDataAction => ({
  type: RECEIVE_REPRESENTATIVE_USER_INITIAL_DATA,
  managementCompanies
})

export const showRepresentativeUserLoading: ShowRepresentativeUserLoadingAction = {
  type: SHOW_REPRESENTATIVE_USER_LOADING
}

export const hideRepresentativeUserLoading: HideRepresentativeUserLoadingAction = {
  type: HIDE_REPRESENTATIVE_USER_LOADING
}

export const openRepresentativeUserModal = (modalType: string, dashboardUserId?: string): OpenRepresentativeUserModalAction => ({
  type: OPEN_REPRESENTATIVE_USER_MODAL,
  modalType,
  dashboardUserId
})

export const closeRepresentativeUserModal: CloseRepresentativeUserModalAction = {
  type: CLOSE_REPRESENTATIVE_USER_MODAL
}

