import {FiltersOnboardingInterface} from "../store";
import {
  FetchOnboardingAction,
  ReceiveOnboardingAction,
  ReceiveInitialDataAction, SelectOnboardingAction,
  UpdateFilterAction, FetchInitialDataAction
} from "./onboarding.action";
import {
  FETCH_ONBOARDING,
  FETCH_ONBOARDING_INITIAL_DATA, HIDE_ONBOARDING_LOADING,
  RECEIVE_ONBOARDING, RECEIVE_ONBOARDING_INITIAL_DATA, SELECT_ONBOARDING, SHOW_ONBOARDING_LOADING,
  UPDATE_ONBOARDING_FILTER
} from "../constants/onboardingConstants";
import {DatatableRequestInterface} from "../interfaces/datatable.request.interface";
import {DatatableResponseInterface} from "../interfaces/datatable.response.interface";
import {UserCompanyInterface} from "../interfaces/user.company.interface";
import {OnboardingDatatableRequestInterface} from "../interfaces/onboarding.datatable.request.interface";

export const updateFilterOnboarding = (payload: FiltersOnboardingInterface, cleanup: boolean = false) : UpdateFilterAction => {
  return {
    type: UPDATE_ONBOARDING_FILTER,
    payload,
    cleanup
  }
}

export const fetchOnboarding = (request: OnboardingDatatableRequestInterface) : FetchOnboardingAction => {
  return {
    type: FETCH_ONBOARDING,
    request: request
  }
}

export const receiveOnboarding = (payload: DatatableResponseInterface) : ReceiveOnboardingAction => {
  return {
    type: RECEIVE_ONBOARDING,
    payload
  }
}

export const fetchInitialDataOnboarding = (isExternalUser: boolean): FetchInitialDataAction => ({
  type: FETCH_ONBOARDING_INITIAL_DATA,
  isExternalUser
})

export const receiveInitialDataOnboarding = (managementCompanies: UserCompanyInterface[]) : ReceiveInitialDataAction => ({
  type: RECEIVE_ONBOARDING_INITIAL_DATA,
  managementCompanies
})

export const selectOnboarding = (id: number) : SelectOnboardingAction => {
  return {
    type: SELECT_ONBOARDING,
    id
  }
}

export const showLoading = {
  type: SHOW_ONBOARDING_LOADING
}

export const hideLoading = {
  type: HIDE_ONBOARDING_LOADING
}
