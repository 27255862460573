
import {
  CLOSE_HOMEOWNER_DETAILS,
  DELETE_PAYMENT_SCHEDULED,
  FETCH_HOMEOWNER_DETAILS,
  HIDE_HOMEOWNER_DETAILS_LOADING, OPEN_HOMEOWNER_DETAILS,
  RECEIVE_HOMEOWNER_DETAILS, RECEIVE_PAYMENTS_SCHEDULED, REFRESH_PAYMENTS_SCHEDULED,
  SHOW_HOMEOWNER_DETAILS_LOADING, TOGGLE_PAYER_ALLOW_PAYMENTS
} from "../constants/homeownerDetails";
import {
  DeletePaymentScheduledAction,
  FetchHomeownerDetailsAction,
  OpenHomeownerDetailsAction,
  ReceiveHomeownerDetailsAction,
  ReceivePaymentsScheduledAction,
  RefreshPaymentsScheduledAction,
  TogglePayerAllowPaymentsAction
} from "./homeowner.details.action";
import PayerDetailsInterface from "../interfaces/payer.details.interface";
import {DatatableResponseInterface} from "../interfaces/datatable.response.interface";

export const fetchHomeownerDetails = (id: number) : FetchHomeownerDetailsAction => {
  return {
    type: FETCH_HOMEOWNER_DETAILS,
    id
  }
}

export const receiveHomeownerDetails = (payerDetails: PayerDetailsInterface, dataSource: DatatableResponseInterface, transactions: DatatableResponseInterface) : ReceiveHomeownerDetailsAction => {
  return {
    type: RECEIVE_HOMEOWNER_DETAILS,
    payerDetails,
    dataSource,
    transactions
  }
}

export const deletePaymentScheduled = (id: number, payerId: number, communityId: number) : DeletePaymentScheduledAction => {
  return {
    type: DELETE_PAYMENT_SCHEDULED,
    id,
    payerId,
    communityId
  }
}

export const receivePaymentsScheduled = (dataSource: DatatableResponseInterface) : ReceivePaymentsScheduledAction => {
  return {
    type: RECEIVE_PAYMENTS_SCHEDULED,
    dataSource
  }
}

export const refreshPaymentsScheduled = (payerId: number) : RefreshPaymentsScheduledAction => {
  return {
    type: REFRESH_PAYMENTS_SCHEDULED,
    payerId
  }
}

export const togglePayerAllowPayments = (allowPayments: boolean, payerId: number) : TogglePayerAllowPaymentsAction => {
  return {
    type: TOGGLE_PAYER_ALLOW_PAYMENTS,
    allowPayments,
    payerId
  }
}

export const showLoading = {
  type: SHOW_HOMEOWNER_DETAILS_LOADING
}

export const hideLoading = {
  type: HIDE_HOMEOWNER_DETAILS_LOADING
}

export const openHomeownerDetails = (payerId: number): OpenHomeownerDetailsAction => {
  return {
    type: OPEN_HOMEOWNER_DETAILS,
    payerId
  }
}

export const closeHomeownerDetails = {
  type: CLOSE_HOMEOWNER_DETAILS
}
