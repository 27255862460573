import {DashboardUsersInterface} from "../store";
import {DashboardUsersTypes} from "../actions/dashboard.user.action";
import {
  CLOSE_DASHBOARD_USER_MODAL,
  DASHBOARD_USER_INITIALIZED,
  HIDE_DASHBOARD_USER_LOADING, OPEN_DASHBOARD_USER_MODAL,
  RECEIVE_DASHBOARD_USER_INITIAL_DATA,
  RECEIVE_DASHBOARD_USERS, SHOW_DASHBOARD_USER_LOADING,
  UPDATE_DASHBOARD_USER_FILTER
} from "../constants/dashboardUserConstants";
import {config} from "../config";

export const dashboardUsersReducer = (state : DashboardUsersInterface = getDashboardUsersInitialState(), action : DashboardUsersTypes): any => {
  switch (action.type) {
    case UPDATE_DASHBOARD_USER_FILTER:
      return {
        ...state,
        filters: {
          ...action.payload
        }
      }
    case RECEIVE_DASHBOARD_USERS:
      return {
        ...state,
        dataSource: {
          ...state.dataSource,
          ...action.payload
        }
      }
    case RECEIVE_DASHBOARD_USER_INITIAL_DATA:
      return {
        ...state,
        initialized: true,
        catalog: {
          managementCompanies: action.managementCompanies
        }
      }
    case SHOW_DASHBOARD_USER_LOADING:
      return {
        ...state,
        loading: true
      }
    case HIDE_DASHBOARD_USER_LOADING:
      return {
        ...state,
        loading: false
      }
    case DASHBOARD_USER_INITIALIZED:
      return {
        ...state,
        initialized: true
      }
    case OPEN_DASHBOARD_USER_MODAL:
      return {
        ...state,
        openDashboardUserModal: true,
        modalType: action.modalType,
        dashboardUserSelectedId: action.dashboardUserId
      }
    case CLOSE_DASHBOARD_USER_MODAL:
      return {
        ...state,
        openDashboardUserModal: false,
      }
    default:
      return state
  }
}

const getDashboardUsersInitialState = (): DashboardUsersInterface => {
  return {
    filters: {
      pageNumber: 1,
      pageSize: config.paymentTransactionsPageSize
    },
    initialized: false,
    loading: false,
    catalog: {},
    dataSource: {limit: config.paymentTransactionsPageSize, offset: 0, itemCount: 0, items: []},
    openDashboardUserModal: false
  }
}
