/**
 * Lockbox files Actions Constants
 *
 * Constants to handle the actions during the Redux process
 *
 */

 export const UPDATE_LOCKBOX_FILTER = 'UPDATE_LOCKBOX_FILTER'
 export const FETCH_LOCKBOXFILES = 'FETCH_LOCKBOXFILES'
 export const RECEIVE_LOCKBOXFILES = 'RECEIVE_LOCKBOXFILES'
 export const FETCH_LOCKBOXFILES_INITIAL_DATA = 'FETCH_LOCKBOXFILES_INITIAL_DATA'
 export const SHOW_LOCKBOXFILE_LOADING = 'SHOW_LOCKBOXFILE_LOADING'
 export const HIDE_LOCKBOXFILE_LOADING = 'HIDE_LOCKBOXFILE_LOADING'