import {TaxFormsInterface} from "../store";
import {config} from "../config";
import {TaxFormsTypes} from "../actions/tax.forms.action";
import {
  HIDE_TAX_FORMS_LOADING,
  RECEIVE_TAX_FORMS,
  RECEIVE_TAX_FORMS_INITIAL_DATA, RECEIVE_TAX_FORMS_STATUS,
  SELECT_TAX_FORMS,
  SHOW_TAX_FORMS_LOADING,
  UPDATE_TAX_FORMS_FILTER
} from "../constants/taxFormsConstants";


export const taxFormsReducer = (state: TaxFormsInterface = getTaxFormsInitialState(), action : TaxFormsTypes): any => {
  switch (action.type) {
    case UPDATE_TAX_FORMS_FILTER:
      return {
        ...state,
        filters: {
          ...action.payload
        }
      }
    case RECEIVE_TAX_FORMS:
      return {
        ...state,
        initialized: true,
        firstDocument: action.firstDocument,
        dataSource: {
          ...state.dataSource,
          ...action.payload
        }
      }
    case RECEIVE_TAX_FORMS_STATUS:
      return {
        ...state,
        firstDocument: action.firstDocument
      }
    case RECEIVE_TAX_FORMS_INITIAL_DATA:
      return {
        ...state,
        initialized: true,
        catalog: {
          managementCompanies: action.managementCompanies,
          communities: action.communities
        }
      }
    case SELECT_TAX_FORMS:
      return {
        ...state,
        communitySelectedId: action.id
      }
    case SHOW_TAX_FORMS_LOADING:
      return {
        ...state,
        loading: true
      }
    case HIDE_TAX_FORMS_LOADING:
      return {
        ...state,
        loading: false
      }
    default:
      return state;
  }
}

const getTaxFormsInitialState = (): TaxFormsInterface => {
  return {
    filters: {
      pageNumber: 1,
      pageSize: config.taxFormsPageSize,
      yearSelected: (new Date().getFullYear() - 1)
    },
    initialized: false,
    catalog: {},
    loading: false,
    dataSource: {limit: config.onboardingPageSize, offset: 0, itemCount: 0, items: []}
  }
}
