import {all, call, fork, put, select, takeLatest, throttle} from 'redux-saga/effects';
import {DatatableRequestInterface} from "../interfaces/datatable.request.interface";
import {createRefundPayment, getCommunities, getPaymentTransactions, getResidents} from "../api/PaymentTransactionAPI";
import {DatatableResponseInterface} from "../interfaces/datatable.response.interface";
import {
  FetchInitialDataAction,
  FetchPaymentTransactionsAction,
  FetchResidentsAction,
  RefundPaymentAction,
  SelectPaymentAction,
  SendReceiptAction,
  UpdateFilterAction
} from "../actions/payment.transaction.action";
import {
  closeRefundPaymentModal,
  closeSendReceiptModal,
  fetchPaymentTransactions,
  hideLoading,
  openRefundPaymentModal,
  openSendReceiptModal,
  receiveInitialData,
  receivePaymentTransactions,
  receiveResidents,
  showLoading,
  updateFilter,
} from "../actions/paymentTransactions";
import {
  FETCH_INITIAL_DATA,
  FETCH_PAYMENT_TRANSACTIONS,
  FETCH_RESIDENTS,
  REFUND_PAYMENT,
  SELECT_PAYMENT,
  SEND_RECEIPT,
  UPDATE_FILTER
} from "../constants/paymentTransactions";
import {
  convertToPaymentTransactionRequest,
  getInitialFilters,
  getNotificationFormat
} from "../utils/helpers";
import {catchException} from "./errorHandlerSaga";
import {sendNotification} from "../actions/notificationHandler";
import {ActionEnum} from "../interfaces/action.enum";
import {sendReceiptApi} from "../api/HomeownersAPI";
import {ApplicationState, FiltersInterface} from "../store";
import Transaction from "../models/transaction.model";
import {getScheduledReport} from "../api/ScheduledReportAPI";
import {ScheduledReport} from "../models/scheduled.report.model";
import {ReportFrequency} from "../interfaces/scheduled.report.interface";
import moment from "moment";

/**
 * Saga to observe FETCH_PAYMENT_TRANSACTIONS action
 */
export function* watchRequestPaymentTransactionsSaga() {
  yield takeLatest(FETCH_PAYMENT_TRANSACTIONS, requestPaymentTransactionsSaga)
}

/**
 * Resolver for FETCH_PAYMENT_TRANSACTIONS
 * @param action
 */
function* requestPaymentTransactionsSaga(action: FetchPaymentTransactionsAction) {
  try {
    yield put(showLoading)
    const response: DatatableResponseInterface = yield call(getPaymentTransactions, action.request)
    yield put(receivePaymentTransactions(response))
  } catch (e) {
    console.warn('Error in requestPaymentTransactionsSaga: ', e)
    yield catchException(e)
  } finally {
    yield put(hideLoading)
  }
  
}

/**
 * Saga to observe UPDATE_FILTER action
 */
export function* watchUpdateFiltersSaga() {
  yield takeLatest(UPDATE_FILTER, updateFilterSaga);
}

/**
 * Resolver for UPDATE_FILTER
 * @param action
 */
function* updateFilterSaga(action: UpdateFilterAction) {
  const request: DatatableRequestInterface = convertToPaymentTransactionRequest(action.payload);
  yield put(fetchPaymentTransactions(request))
}

/**
 * Saga to observe FETCH_INITIAL_DATA
 */
export function* watchFetchInitialDateSaga() {
  yield takeLatest(FETCH_INITIAL_DATA, fetchInitialDataSaga);
}

/**
 * Resolve FETCH_INITIAL_DATA
 */
function* fetchInitialDataSaga(action: FetchInitialDataAction) {
  try {
    let request: FiltersInterface = getInitialFilters()
    let isScheduledReport = false;
    const communities = yield call(getCommunities);
    const paymentTypes = [{paymentMethodTypeName:"Card"},{paymentMethodTypeName:"Ach"}];
    if (!!action.reportId) {
      try {
        const report = yield call(getScheduledReport, action.reportId);
        if (!!report && !!report.id) {
          const scheduledReport = new ScheduledReport(report);
          request.status = scheduledReport.statusCriteria.split(",");
          switch (scheduledReport.frequency) {
            case ReportFrequency.Daily:
              request.selectedStartDate = moment().subtract(1, "days").toDate();
              break;
            case ReportFrequency.Weekly:
              request.selectedStartDate = moment().subtract(7, "days").toDate();
              break;
          }
          isScheduledReport = true;
        }
      } catch (e) {
        console.warn("Scheduled Report Not Found");
      }
    }
    yield put(receiveInitialData(communities, paymentTypes))
    yield put(updateFilter(request));
    
    if (isScheduledReport) {
      localStorage.setItem("exportFile", "true");
    }
  } catch (e) {
    console.warn('Error in fetchInitialDataSaga: ', e)
    yield catchException(e)
  }
}

/**
 * Saga to observe FETCH_RESIDENTS
 */
export function* watchFetchResidentsSaga() {
  yield throttle(500, FETCH_RESIDENTS, fetchResidentsSaga);
}

/**
 * Resolve FETCH_RESIDENTS
 */
function* fetchResidentsSaga(action: FetchResidentsAction) {
  try {
    if (action.communityID) {
      yield put(showLoading)
      const residents = yield call(getResidents, action.communityID)
      yield put(receiveResidents(residents))
    }
  } catch (e) {
    console.warn('Error in fetchResidentsSaga: ', e)
    yield catchException(e)
  } finally {
    yield put(hideLoading)
  }
}

/**
 * Create refund payment
 * @param action
 */
function* refundPayment(action: RefundPaymentAction) {
  let success = false;
  try {
    if (action.paymentID) {
      yield put(showLoading)
      const state = yield select();
      yield call(createRefundPayment, action.paymentID, action.reason, state.userInfo.email)
      yield put(closeRefundPaymentModal);
      success = true;
    }
  } catch (e) {
    console.warn('Error in refundPayment: ', e)
    yield catchException(e)
  } finally {
    yield put(hideLoading)
    if (success) {
      yield put(sendNotification(getNotificationFormat(
        'info',
        'Refund Initiated!',
        'A refund has been initiated for the homeowner and is currently pending. As a reminder, refunds for ACH (eCheck) can take up to 10 business days.',
        true
      )))
    }
  }
}

/**
 * Saga to observe REFUND_PAYMENT
 */
export function* watchRefundPayment() {
  yield takeLatest(REFUND_PAYMENT, refundPayment);
}

/**
 * Opens a model after the payment is selected
 * @param action
 */
function* selectPaymentSaga(action: SelectPaymentAction) {
  if (!!action.payment && !!action.action) {
    switch (action.action) {
      case ActionEnum.REFUND_PAYMENT: {
        yield put(openRefundPaymentModal);
        break;
      }
      case ActionEnum.SEND_RECEIPT: {
        yield put(openSendReceiptModal);
        break;
      }
    }
  }
}

/**
 * Saga to observe SELECT_PAYMENT
 */
export function* watchSelectPayment() {
  yield takeLatest(SELECT_PAYMENT, selectPaymentSaga);
}

/**
 * Send receipt
 * @param action
 */
function* sendReceiptSaga(action: SendReceiptAction) {
  let success = false;
  yield put(showLoading);
  const state: ApplicationState = yield select();
  let email = state.paymentTransaction.dataSource?.items?.map(x => x as Transaction)
    .filter((payment: Transaction) => payment.id === action.paymentID)[0].payerEmailAddress;
  try {
    if (action.paymentID) {
      yield call(sendReceiptApi, action.paymentID);
      success = true;
    }
  } catch (e) {
    console.warn('Error in sendReceiptSaga: ', e)
    yield catchException(e);
  } finally {
    yield put(hideLoading)
    yield put(closeSendReceiptModal);
    if (success) {
      yield put(sendNotification(getNotificationFormat(
        'success',
        'Receipt is on it\'s way!',
        `Receipt sent to ${email} successfully.`,
        true
      )))
    }
  }
}

/**
 * Saga to observe SEND_RECEIPT
 */
export function* watchSendReceipt() {
  yield takeLatest(SEND_RECEIPT, sendReceiptSaga);
}

export function* paymentTransactionSagas() {
  yield all([
    fork(watchRequestPaymentTransactionsSaga),
    fork(watchUpdateFiltersSaga),
    fork(watchFetchInitialDateSaga),
    fork(watchFetchResidentsSaga),
    fork(watchRefundPayment),
    fork(watchSelectPayment),
    fork(watchSendReceipt)
  ]);
}
