import {all, call, fork, put, takeLatest, throttle} from "redux-saga/effects";
import {FETCH_REPORTS_INITIAL_DATA, FETCH_REPORTS_RESIDENTS} from "../constants/reportsConstants";
import {FetchReportsInitialDataAction, FetchReportsResidentsAction} from "../actions/reports.action";
import {getCommunities} from "../api/HomeownersAPI";
import {catchException} from "./errorHandlerSaga";
import {
  hideReportsLoading,
  receiveReportsInitialData,
  receiveReportsResidents,
  showReportsLoading
} from "../actions/reports";
import {getResidents} from "../api/PaymentTransactionAPI";

/**
 * Saga to observe FETCH_INITIAL_DATA
 */
export function* watchFetchReportsInitialDateSaga() {
  yield takeLatest(FETCH_REPORTS_INITIAL_DATA, fetchReportsInitialDataSaga);
}

/**
 * Resolve FETCH_INITIAL_DATA
 */
function* fetchReportsInitialDataSaga(action: FetchReportsInitialDataAction) {
  try {
    const communities = yield call(getCommunities);
    yield put(receiveReportsInitialData(communities))
  } catch (e) {
    console.warn('Error in fetchReportsInitialDataSaga: ', e)
    yield catchException(e)
  }
}

/**
 * Saga to observe FETCH_RESIDENTS
 */
export function* watchFetchReportsResidentsSaga() {
  yield throttle(500, FETCH_REPORTS_RESIDENTS, fetchReportsResidentsSaga);
}

/**
 * Resolve FETCH_RESIDENTS
 */
function* fetchReportsResidentsSaga(action: FetchReportsResidentsAction) {
  try {
    if (action.communityID) {
      yield put(showReportsLoading)
      const residents = yield call(getResidents, action.communityID)
      yield put(receiveReportsResidents(residents))
    }
  } catch (e) {
    console.warn('Error in fetchReportsResidentsSaga: ', e)
    yield catchException(e)
  } finally {
    yield put(hideReportsLoading)
  }
}

export function* reportsSagas() {
  yield all([
    fork(watchFetchReportsResidentsSaga),
    fork(watchFetchReportsInitialDateSaga),
  ]);
}
