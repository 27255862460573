import {
    UPDATE_HOMEOWNERS_FILTER,
    FETCH_HOMEOWNERS,
    RECEIVE_HOMEOWNERS,
    FETCH_HOMEOWNERS_INITIAL_DATA,
    RECEIVE_HOMEOWNERS_INITIAL_DATA,
    SHOW_HOMEOWNERS_LOADING,
    HIDE_HOMEOWNERS_LOADING, SELECT_HOMEOWNER,
} from "../constants/homeowners"
import {FiltersHomeownersInterface} from "../store";
import {DatatableResponseInterface} from "../interfaces/datatable.response.interface";
import {DatatableRequestInterface} from "../interfaces/datatable.request.interface";
import {CommunityInterface} from "../interfaces/community.interface";
import {
    FetchHomeownersAction,
    ReceiveHomeownersAction,
    ReceiveInitialDataAction, SelectHomeownerAction,
    UpdateFilterAction
} from "./homeowners.action";

export const updateFilter = (payload: FiltersHomeownersInterface) : UpdateFilterAction => {
    return {
        type: UPDATE_HOMEOWNERS_FILTER,
        payload
    }
}

export const fetchHomeowners = (request: DatatableRequestInterface) : FetchHomeownersAction => {
    return {
        type: FETCH_HOMEOWNERS,
        request: request
    }
}

export const receiveHomeowners = (payload: DatatableResponseInterface) : ReceiveHomeownersAction => {
    return {
        type: RECEIVE_HOMEOWNERS,
        payload
    }
}

export const fetchInitialData = {
    type: FETCH_HOMEOWNERS_INITIAL_DATA
}

export const receiveInitialData = (communities: CommunityInterface[]) : ReceiveInitialDataAction => {
    return {
        type: RECEIVE_HOMEOWNERS_INITIAL_DATA,
        communities
    }
}

export const selectHomeowner = (id: number) : SelectHomeownerAction => {
    return {
        type: SELECT_HOMEOWNER,
        id
    }
}

export const showLoading = {
    type: SHOW_HOMEOWNERS_LOADING
}

export const hideLoading = {
    type: HIDE_HOMEOWNERS_LOADING
}
