/**
 * Dashboard User Actions Constants
 *
 * Constants to handle the actions during the Redux process
 *
 */

export const UPDATE_DASHBOARD_USER_FILTER = 'UPDATE_DASHBOARD_USER_FILTER'
export const FETCH_DASHBOARD_USERS = 'FETCH_DASHBOARD_USERS'
export const RECEIVE_DASHBOARD_USERS = 'RECEIVE_DASHBOARD_USERS'
export const RECEIVE_DASHBOARD_USER_INITIAL_DATA = 'RECEIVE_DASHBOARD_USER_INITIAL_DATA'
export const FETCH_DASHBOARD_USER_INITIAL_DATA = 'FETCH_DASHBOARD_USER_INITIAL_DATA'
export const SHOW_DASHBOARD_USER_LOADING = 'SHOW_DASHBOARD_USER_LOADING'
export const HIDE_DASHBOARD_USER_LOADING = 'HIDE_DASHBOARD_USER_LOADING'
export const DASHBOARD_USER_INITIALIZED = 'DASHBOARD_USER_INITIALIZED'
export const OPEN_DASHBOARD_USER_MODAL = 'OPEN_DASHBOARD_USER_MODAL'
export const CLOSE_DASHBOARD_USER_MODAL = 'CLOSE_DASHBOARD_USER_MODAL'
export const DELETE_DASHBOARD_USER = 'DELETE_DASHBOARD_USER'
