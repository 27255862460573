import {all, fork, put, call, takeLatest, select} from 'redux-saga/effects';
import {
  DatatableRequestInterface
} from "../interfaces/datatable.request.interface";
import {getCommunities, getCommunitiesDatatable, getDocumentsInformation} from "../api/TaxFormsAPI";
import {DatatableResponseInterface} from "../interfaces/datatable.response.interface";
import {FetchInitialDataAction, FetchTaxFormsAction, UpdateFilterAction} from "../actions/tax.forms.action";
import {
  fetchTaxForms,
  receiveTaxForms,
  showLoading,
  hideLoading,
  receiveInitialDataTaxForms, receiveTaxFormsStatus
} from "../actions/tax.forms";
import {
  FETCH_TAX_FORMS_INITIAL_DATA,
  FETCH_TAX_FORMS,
  UPDATE_TAX_FORMS_FILTER, FETCH_TAX_FORMS_STATUS
} from "../constants/taxFormsConstants";
import {
  convertToTaxFormsRequest,
  getTaxFormsInitialRequest
} from "../utils/helpers";
import {catchException} from "./errorHandlerSaga";
import {TaxFormsDatatableRequestInterface} from "../interfaces/taxforms.datatable.request.interface";
import {fetchManagementCompanies} from "../api/Auth0API";

/**
 * Saga to observe FETCH_TAX_FORMS action
 */
export function* watchRequestTaxFormsSaga() {
  yield takeLatest(FETCH_TAX_FORMS, requestTaxFormsSaga)
}

/**
 * Resolver for FETCH_TAX_FORMS
 * @param action
 */
function* requestTaxFormsSaga(action: FetchTaxFormsAction) {
  try {
    yield put(showLoading)
    const documentResp = yield call(getDocumentsInformation, action.request.managementCompanyId || 0, action.request.year);
    const response: DatatableResponseInterface = yield call(getCommunitiesDatatable, action.request)
    yield put(receiveTaxForms(response, documentResp))
  } catch (e) {
    console.warn('Error in requestTaxFormsSaga: ', e)
    yield catchException(e)
  } finally {
    yield put(hideLoading)
  }
  
}

/**
 * Saga to observe UPDATE_TAX_FORMS_FILTER action
 */
export function* watchUpdateFiltersSaga() {
  yield takeLatest(UPDATE_TAX_FORMS_FILTER, updateFilterSaga);
}

/**
 * Resolver for UPDATE_TAX_FORMS_FILTER
 * @param action
 */
function* updateFilterSaga(action: UpdateFilterAction) {
  if (!action.cleanup) {
    const req: TaxFormsDatatableRequestInterface = convertToTaxFormsRequest(action.payload);
    yield put(fetchTaxForms(req))
    
    if (!req.communityId && !!req.managementCompanyId) {
      const state = yield select();
      const communities = yield call(getCommunities, req.managementCompanyId);
      yield put(receiveInitialDataTaxForms(state.taxForms.catalog.managementCompanies, communities));
    }
  } else {
    yield put(receiveTaxForms( {
      offset: 0,
      limit: 0,
      itemCount: 0,
      items: []
    }))
  }
}

/**
 * Saga to observe FETCH_TAX_FORMS_INITIAL_DATA
 */
export function* watchFetchInitialDataSaga() {
  yield takeLatest(FETCH_TAX_FORMS_INITIAL_DATA, fetchInitialDataSaga);
}

/**
 * Resolve FETCH_TAX_FORMS_INITIAL_DATA
 */
function* fetchInitialDataSaga(action: FetchInitialDataAction) {
  try {
    if (action.isFSEmployee) {
      const managementCompanies = yield call(fetchManagementCompanies);
      yield put(receiveInitialDataTaxForms(managementCompanies, []));
    } else {
      const request: DatatableRequestInterface = getTaxFormsInitialRequest()
      const response = yield call(getCommunitiesDatatable, request)
      const state = yield select();
      const documentResp = yield call(getDocumentsInformation, 0, state.taxForms.filters.yearSelected);
      yield put(receiveTaxForms(response, documentResp))
    }
  } catch (e) {
    console.warn('Error in fetchInitialDataSaga: ', e)
    yield catchException(e)
  }
}

/**
 * Saga to observe FETCH_TAX_FORMS_STATUS
 */
export function* watchFetchTaxFormsStatusSaga() {
  yield takeLatest(FETCH_TAX_FORMS_STATUS, fetchTaxFormStatusSaga);
}

/**
 * Resolve FETCH_TAX_FORMS_INITIAL_DATA
 */
function* fetchTaxFormStatusSaga(action: FetchInitialDataAction) {
  try {
    const state = yield select();
    const managementCompanyId = state.taxForms.filters.managementCompanySelected ? state.taxForms.filters.managementCompanySelected.id : undefined
    const documentResp = yield call(getDocumentsInformation, managementCompanyId, state.taxForms.filters.yearSelected);
    yield put(receiveTaxFormsStatus(documentResp));
  } catch (e) {
    console.warn('Error in fetchInitialDataSaga: ', e)
    yield catchException(e)
  }
}

export function* taxFormsSagas() {
  yield all([
    fork(watchRequestTaxFormsSaga),
    fork(watchUpdateFiltersSaga),
    fork(watchFetchInitialDataSaga),
    fork(watchFetchTaxFormsStatusSaga)
  ]);
}
