import {OnboardingInterface} from "../store";
import {config} from "../config";
import {OnboardingTypes} from "../actions/onboarding.action";
import {
  HIDE_ONBOARDING_LOADING,
  RECEIVE_ONBOARDING, RECEIVE_ONBOARDING_INITIAL_DATA,
  SELECT_ONBOARDING,
  SHOW_ONBOARDING_LOADING,
  UPDATE_ONBOARDING_FILTER
} from "../constants/onboardingConstants";


export const onboardingReducer = (state: OnboardingInterface = getOnboardingInitialState(), action : OnboardingTypes): any => {
  switch (action.type) {
    case UPDATE_ONBOARDING_FILTER:
      return {
        ...state,
        filters: {
          ...action.payload
        }
      }
    case RECEIVE_ONBOARDING:
      return {
        ...state,
        initialized: true,
        dataSource: {
          ...state.dataSource,
          ...action.payload
        }
      }
    case RECEIVE_ONBOARDING_INITIAL_DATA:
      return {
        ...state,
        initialized: true,
        catalog: {
          managementCompanies: action.managementCompanies
        }
      }
    case SELECT_ONBOARDING:
      return {
        ...state,
        onboardingSelectedId: action.id
      }
    case SHOW_ONBOARDING_LOADING:
      return {
        ...state,
        loading: true
      }
    case HIDE_ONBOARDING_LOADING:
      return {
        ...state,
        loading: false
      }
    default:
      return state;
  }
}

const getOnboardingInitialState = (): OnboardingInterface => {
  return {
    filters: {
      pageNumber: 1,
      pageSize: config.onboardingPageSize
    },
    initialized: false,
    catalog: {},
    loading: false,
    dataSource: {limit: config.onboardingPageSize, offset: 0, itemCount: 0, items: []}
  }
}
