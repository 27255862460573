import {
    CLOSE_REFUND_PAYMENT_MODAL, CLOSE_SEND_RECEIPT_MODAL,
    FETCH_INITIAL_DATA,
    FETCH_PAYMENT_TRANSACTIONS,
    FETCH_RESIDENTS,
    HIDE_LOADING,
    INITIALIZED, OPEN_REFUND_PAYMENT_MODAL, OPEN_SEND_RECEIPT_MODAL,
    RECEIVE_INITIAL_DATA,
    RECEIVE_PAYMENT_TRANSACTIONS,
    RECEIVE_RESIDENTS,
    REFUND_PAYMENT,
    SELECT_PAYMENT, SEND_RECEIPT,
    SHOW_LOADING,
    UPDATE_FILTER
} from "../constants/paymentTransactions"
import {FiltersInterface} from "../store";
import {DatatableResponseInterface} from "../interfaces/datatable.response.interface";
import {DatatableRequestInterface} from "../interfaces/datatable.request.interface";
import {CommunityInterface} from "../interfaces/community.interface";
import {
    FetchInitialDataAction,
    FetchPaymentTransactionsAction,
    FetchResidentsAction,
    ReceiveInitialDataAction,
    ReceivePaymentTransactionsAction,
    ReceiveResidentsAction,
    RefundPaymentAction,
    SelectPaymentAction, SendReceiptAction,
    UpdateFilterAction
} from "./payment.transaction.action";
import {ResidentInterface} from "../interfaces/resident.interface";
import {PaymentTypeInterface} from "../interfaces/payment.type.interface";
import {RefundedReasonEnum} from "../interfaces/refunded.reason.enum";
import {TransactionInterface} from "../interfaces/transaction.interface";
import {ActionEnum} from "../interfaces/action.enum";

export const updateFilter = (payload: FiltersInterface) : UpdateFilterAction => {
    return {
        type: UPDATE_FILTER,
        payload
    }
}

export const fetchPaymentTransactions = (request: DatatableRequestInterface) : FetchPaymentTransactionsAction => {
    return {
        type: FETCH_PAYMENT_TRANSACTIONS,
        request: request
    }
}

export const receivePaymentTransactions = (payload: DatatableResponseInterface) : ReceivePaymentTransactionsAction => {
    return {
        type: RECEIVE_PAYMENT_TRANSACTIONS,
        payload
    }
}

export const fetchInitialData = (reportId?: number): FetchInitialDataAction => {
    return {
        type: FETCH_INITIAL_DATA,
        reportId
    }
}

export const receiveInitialData = (communities: CommunityInterface[], paymentTypes: PaymentTypeInterface[]) : ReceiveInitialDataAction => {
    return {
        type: RECEIVE_INITIAL_DATA,
        communities,
        paymentTypes
    }
}

export const fetchResidents = (communityID: number) : FetchResidentsAction => {
    return {
        type: FETCH_RESIDENTS,
        communityID
    }
}

export const receiveResidents = (residents: ResidentInterface[]) : ReceiveResidentsAction => {
    return {
        type: RECEIVE_RESIDENTS,
        residents
    }
}

export const selectPayment = (payment: TransactionInterface, action: ActionEnum): SelectPaymentAction => {
    return {
        type: SELECT_PAYMENT,
        payment,
        action
    }
}

export const refundPayment = (paymentID: number, reason: RefundedReasonEnum): RefundPaymentAction => {
    return {
        type: REFUND_PAYMENT,
        paymentID,
        reason
    }
}

export const sendReceipt = (paymentID: number): SendReceiptAction => {
    return {
        type: SEND_RECEIPT,
        paymentID,
    }
}

export const showLoading = {
    type: SHOW_LOADING
}

export const hideLoading = {
    type: HIDE_LOADING
}

export const openRefundPaymentModal = {
    type: OPEN_REFUND_PAYMENT_MODAL
}

export const closeRefundPaymentModal = {
    type: CLOSE_REFUND_PAYMENT_MODAL
}

export const openSendReceiptModal = {
    type: OPEN_SEND_RECEIPT_MODAL
}

export const closeSendReceiptModal = {
    type: CLOSE_SEND_RECEIPT_MODAL
}

export const initialized = {
    type: INITIALIZED
}
